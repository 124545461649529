import React, { useRef, useState } from 'react'

import playstore from 'assets/icons/play_store.svg'
import appstore from 'assets/icons/app_store.svg'
import mobileApp from 'assets/globals/mobileApp.png'

import one from 'assets/specs-icons/05/icon-01.svg'
import two from 'assets/specs-icons/05/icon-02.svg'
import three from 'assets/specs-icons/05/icon-03.svg'
import four from 'assets/specs-icons/05/icon-04.svg'

import lorawan_sv from 'assets/products/lms-mobile/LMS-mobile-IMG-01.png'
import lorawan_fv from 'assets/products/lms-mobile/LMS-mobile-IMG-02.png'
import lorawan_lv from 'assets/products/lms-mobile/LMS-mobile-IMG-03.png'
import lorawan_rv from 'assets/products/lms-mobile/LMS-mobile-IMG-04.png'

import lorawan_sv_small from 'assets/products/lms-mobile/Small/LMS-mobile-IMG-small-01.png'
import lorawan_fv_small from 'assets/products/lms-mobile/Small/LMS-mobile-IMG-small-02.png'
import lorawan_lv_small from 'assets/products/lms-mobile/Small/LMS-mobile-IMG-small-03.png'
import lorawan_rv_small from 'assets/products/lms-mobile/Small/LMS-mobile-IMG-small-04.png'
// import lorawan_sv_small from 'assets/products/lorawan-street-view_small.webp'
import gatewayBrochure from 'assets/pdf/lorawan_brochure.pdf'

import { Image } from 'atoms/image'
import { MasterNav } from 'atoms/links'
import globe from 'assets/icons/globe.svg'
import dashed_line from 'assets/icons/dashed-line.svg'
import StatisticsTable from 'organisms/statisticsTable'
import gatewayData from 'static/gatewayTable.json'
import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'
import ListImage from 'atoms/listImage'

const screenName = ['Login screen', 'Home screen', 'Dashboard', 'Device alert']

export default function SoftwareApplication() {
  const [idx, setIdx] = useState(0)
  const imgRef = useRef(null)

  const exitAnim = (node: any) => {
    gsap.fromTo(
      node,
      { scale: 1, opacity: 1 },
      {
        opacity: 0,
        scale: 0.95,
        duration: 0.5,
      },
    )
  }

  const enter = (node: any) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        scale: 0.95,
      },
      {
        duration: 0.5,
        scale: 1,
        opacity: 1,
      },
    )
  }

  return (
    <div
      id="application"
      className="blade-top-padding-sm mt-12 md:mt-16 lg:mt-20 xl:mt-28 bg-black lg:blade-top-padding 
    blade-bottom-padding-sm lg:blade-bottom-padding"
    >
      <div className="bg-no-repeat bg-cover bg-center w-container lg:px-10">
        {/* Mobile View */}
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="500"
          data-aos-once="false"
          className="basis-10 md:hidden pt-1 2xl:pt-3 grow-0 shrink pb-5 
          flex flex-col"
        >
          <Image
            src={globe}
            alt="Globe Icon"
            className="select-none h-8 md:h-auto mb-3 md:mb-0  mr-auto"
          />

          <h3 className="text-blue-gradient text-transparent block md:hidden bg-clip-text font-semibold">
            Lighting Management Software
          </h3>
        </div>

        <div
          className="flex-col-reverse lg:flex-row gap-x-10 
        relative flex"
        >
          <div
            className="basis-96 md:pt-6 lg:pt-0 grow shrink flex flex-col md:flex-row 
          flex-nowrap"
          >
            <div
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="false"
              className="basis-10 grow-0 shrink md:flex hidden flex-col"
            >
              <Image
                src={globe}
                alt="Globe Icon"
                className="select-none h-8 md:h-auto mb-3 md:mb-0  mr-auto"
              />
              <Image
                src={dashed_line}
                alt="Dashed Line"
                className="mx-auto select-none md:block hidden"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="false"
              className="flex-1 md:pl-3 lg:pl-7 shrink  max-w-xl flex flex-col gap-4 
              flex-nowrap mt-4 lg:mt-0"
            >
              <h3 className="text-blue-gradient text-transparent md:block hidden bg-clip-text font-semibold">
                LMS Mobile Application
              </h3>
              <span className="text-gray font-normal text-base  md:text-lg   max-w-md xl:max-w-xl">
                The ViaLights’ Mobile Lighting Management Software provides user
                access to monitor real-time light status and alerts for quick
                identifications and maintenance purposes. It is a handy tool for
                street light contractors and system integrators during the
                maintenance of smart street light applications in the field.
              </span>
              <div className="flex-1  max-w-2xl flex flex-col gap-4 flex-nowrap">
                <div className=" mt-3 md:mt-4 flex-1 flex md:flex-row flex-col items-start gap-1 gap-y-7 md:gap-x-4 lg:gap-x-10">
                  <div className="flex flex-col gap-y-6 lg:gap-y-8">
                    <div className="flex font-normal justify-between flex-col gap-y-6 lg:gap-y-8 text-white lg:flex-row items-start gap-x-4">
                      <div className="w-[80%] lg:w-[50%] font-normal">
                        <ListImage
                          image={one}
                          text="Keeps a tab on energy consumption."
                        />
                      </div>
                      <div className="w-[80%] lg:w-[50%] font-normal">
                        <ListImage
                          image={two}
                          text=" Checks online/offline status of controller-enabled street lights."
                        />
                      </div>
                    </div>
                    <div className="flex justify-between font-normal text-white gap-y-6 lg:gap-y-8 flex-col items-start gap-x-4 lg:flex-row">
                      <div className="w-[80%] lg:w-[40%] font-normal">
                        <ListImage
                          image={three}
                          text="Alerts received in the last 7 days."
                        />
                      </div>
                      <div className="w-[80%] lg:w-[50%] font-normal">
                        <ListImage
                          image={four}
                          text="Details of offline lamps, street light malfunctions and short circuits."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
            data-aos-once="false"
            className="mb-4 md:px-2 w-full h-fit lg:sticky top-[200px] max-w-[500px] overflow-hidden grow shrink "
          >
            <div
              className="bg-darkGray w-full object-cover rounded-lg 
              overflow-hidden"
            >
              <SwitchTransition>
                <Transition
                  appear={true}
                  timeout={500}
                  in={true}
                  key={idx}
                  onExit={exitAnim}
                  onEnter={enter}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="h-80 md:h-96 w-full relative">
                    <div>
                      <h5
                        className="absolute font-normal text-center top-4 left-0 right-0 z-10 
                    text-white"
                      >
                        {screenName[idx]}
                      </h5>
                    </div>
                    <img
                      src={lorawan_rv}
                      className={` ${
                        idx === 0 ? '' : 'hidden'
                      }  h-full w-full object-center object-cover`}
                      alt="front view loranwan gatway product"
                      ref={imgRef}
                    />
                    <img
                      src={lorawan_fv}
                      className={` ${
                        idx === 1 ? '' : 'hidden '
                      }   h-full w-full object-center object-cover `}
                      alt="left view loranwan gatway product"
                      ref={imgRef}
                    />

                    <img
                      src={lorawan_sv}
                      className={` ${
                        idx === 2 ? '' : 'hidden '
                      }   h-full w-full object-center object-cover `}
                      alt="right view loranwan gatway product"
                      ref={imgRef}
                    />
                    <img
                      src={lorawan_lv}
                      className={` ${
                        idx === 3 ? '' : 'hidden '
                      }  h-full w-full object-cover  `}
                      alt="right view loranwan gatway product"
                      ref={imgRef}
                    />
                  </div>
                </Transition>
              </SwitchTransition>
            </div>

            <div className="mt-8 h-20 flex flex-row gap-4">
              <button
                onClick={() => setIdx(0)}
                className={` ${
                  idx === 0
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 active:outline-none focus:outline-none sm:w-20 md:h-20 
                md:w-24 xl:w-28 bg-gray bg-opacity-20 flex items-center justify-center 
                overflow-hidden rounded-lg`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_rv_small}
                  alt="lorawan"
                  className="!h-full !w-full rounded-lg"
                />
              </button>

              <button
                onClick={() => setIdx(1)}
                className={` ${
                  idx === 1
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 focus:outline-none active:outline-none flex items-center 
                justify-center bg-gray bg-opacity-20 rounded-lg overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_fv_small}
                  alt="lorawan"
                  className="!h-full !w-full rounded-lg"
                />
              </button>

              <button
                onClick={() => setIdx(2)}
                className={` ${
                  idx === 2
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 focus:outline-none active:outline-none flex items-center 
                justify-center bg-gray bg-opacity-20 rounded-lg overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_sv_small}
                  alt="lorawan"
                  className="!h-full !w-full rounded-lg"
                />
              </button>
              <button
                onClick={() => setIdx(3)}
                className={` ${
                  idx === 3
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 focus:outline-none active:outline-none flex items-center 
                justify-center bg-gray bg-opacity-20 rounded-lg overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_lv_small}
                  alt="lorawan"
                  className="!h-full !w-full rounded-lg"
                />
              </button>
            </div>

            {/* Application links */}
            {/* <div
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="false"
              className="md:pt-8"
            >
              <div className="flex d flex-nowrap gap-4 items-center justify-start">
                <div className="">
                  <a
                    href={process.env.REACT_APP_PLAYSTORE}
                    className="disabled-link"
                  >
                    <img
                      alt="Click here to download mobile app"
                      src={playstore}
                      className="h-12 cursor-pointer w-auto object-center object-contain"
                    />
                  </a>
                </div>
                <div className="">
                  <a
                    href={process.env.REACT_APP_APPSTORE}
                    className="disabled-link"
                  >
                    <img
                      alt="Click here to download mobile app"
                      src={appstore}
                      className="h-12 cursor-pointer w-auto object-center object-contain"
                    />
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* statistics table */}

      <div
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="500"
        data-aos-once="false"
        className="blade-bottom-padding hidden blade-top-padding-sm"
      >
        <h5 className="text-dark font-bold text-center px-20 pb-2 ">
          What gives LoRaWAN Gateway <br /> an edge?
        </h5>

        <div className=" max-w-screen-sm lg:w-full  mx-auto">
          <StatisticsTable viewData={gatewayData} />
        </div>
      </div>
    </div>
  )
}
