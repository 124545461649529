import React, { useRef, useState } from 'react'
import nema_fv from 'assets/products/nema-front-view.webp'
import nema_fv_small from 'assets/products/nema-front-view-small.webp'
import nema_sv from 'assets/products/nema-street-view.jpg'
import nema_sv_small from 'assets/products/nema-street-view-small.webp'
import nema_rv from 'assets/products/NEMA-right-big.webp'
import globe from 'assets/icons/globe.svg'
import dashed_line from 'assets/icons/dashed-line.svg'
import '../../home/products/style.css'

import RemoteOnOff from 'assets/specs-icons/01/remote-on-off.svg'
import two from 'assets/specs-icons/01/Website icons SVG-02.svg'
import DimingControl from 'assets/specs-icons/01/Diming-control.svg'
import PowerManagement from 'assets/specs-icons/01/power-management.svg'
import six from 'assets/specs-icons/01/Website icons SVG-06.svg'

import Astrological from 'assets/specs-icons/01/Astronomical schedule.svg'
import PlugPlay from 'assets/specs-icons/01/Plug and Play.svg'
import AlertMonitoring from 'assets/specs-icons/01/alert-monitoring.svg'
import LightSensor from 'assets/specs-icons/01/Integrated Light sensor.svg'
import Connector from 'assets/specs-icons/01/Connector.svg'

import { TextNav } from 'atoms/links'
import nemaPDF from 'assets/pdf/NEMA-Mounted Street Light Controller.pdf'

import { Image } from 'atoms/image'

import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'
import ListImage from 'atoms/listImage'

export default function NemaMounted() {
  const [idx, setIdx] = useState(0)
  const imgRef = useRef(null)

  const exitAnim = (node: any) => {
    gsap.fromTo(
      node,
      { scale: 1, opacity: 1 },
      {
        opacity: 0,
        scale: 0.95,
        duration: 0.5,
      },
    )
  }

  const enter = (node: any) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        scale: 0.95,
      },
      {
        duration: 0.5,
        scale: 1,
        opacity: 1,
      },
    )
  }

  return (
    <div className="w-container lg:blade-top-padding-sm">
      <div className="lg:inline-flex hidden w-10 float-left mr-5 pt-1 2xl:pt-3 grow-0 shrink flex-col justify-center">
        <Image
          src={globe}
          alt="Globe Icon"
          className="select-none h-8 md:h-auto mb-3 md:mb-0  mr-auto"
        />
        <Image
          src={dashed_line}
          alt="Dashed Line"
          className="mx-auto select-none md:block hidden"
        />
      </div>
      <h3
        className="text-gold-gradient mb-4 text-transparent md:hidden block bg-clip-text 
      font-medium  xl:max-w-2xl"
      >
        NEMA Mount Street Light Controller
      </h3>
      <div
        className="lg:ml-10 mb-8 grid lg:sticky top-[200px] place-content-center lg:float-right 
        max-w-[500px] overflow-hidden
      "
      >
        <div className="min-w-full rounded-lg overflow-hidden">
          <div
            className="bg-white product-card h-80 sm:h-96 !w-full object-cover 
              rounded-lg overflow-hidden"
          >
            <SwitchTransition>
              <Transition
                appear={true}
                timeout={500}
                in={true}
                key={idx}
                onExit={exitAnim}
                onEnter={enter}
                mountOnEnter
                unmountOnExit
              >
                <div className="h-full w-full overflow-2xl overflow-hidden">
                  <img
                    src={nema_sv}
                    className={` ${
                      idx === 0 ? '' : 'hidden'
                    }  !h-full w-full object-center object-cover `}
                    alt="left view CCMS product"
                    ref={imgRef}
                  />
                  <img
                    src={nema_fv}
                    className={` ${
                      idx === 1 ? '' : 'hidden'
                    } h-full w-full object-center object-cover  `}
                    alt="front view CCMS product"
                    ref={imgRef}
                  />
                  <img
                    src={nema_rv}
                    className={` ${
                      idx === 2 ? '' : 'hidden'
                    } !h-full w-full object-center object-cover  `}
                    alt="front view CCMS product"
                    ref={imgRef}
                  />
                </div>
              </Transition>
            </SwitchTransition>
          </div>
        </div>
        <div className="mt-6 md:mt-8 h-20 flex items-center flex-row gap-4">
          <button
            onClick={() => setIdx(0)}
            className={` ${
              idx === 0
                ? 'border-[3px] border-solid border-gray border-opacity-60'
                : ''
            } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 bg-gray bg-opacity-20 flex items-center justify-center 
            overflow-hidden rounded-lg`}
            aria-label="thumbnail"
          >
            <Image
              src={nema_sv_small}
              alt="ccms"
              className="object-cover h-full !w-full"
            />
          </button>
          <button
            onClick={() => setIdx(1)}
            className={` ${
              idx === 1
                ? 'border-[3px] border-solid border-gray border-opacity-60'
                : ''
            } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 focus:outline-none active:outline-none flex items-center 
            justify-center bg-white p-1 md:p-3 overflow-hidden rounded-lg`}
            aria-label="thumbnail"
          >
            <Image
              src={nema_fv_small}
              alt="ccms"
              className="h-full w-full object-cover"
            />
          </button>
          <button
            onClick={() => setIdx(2)}
            className={` ${
              idx === 2
                ? 'border-[3px] border-solid border-gray border-opacity-60'
                : ''
            } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 focus:outline-none active:outline-none  bg-white overflow-hidden rounded-lg`}
            aria-label="thumbnail"
          >
            <Image
              src={nema_rv}
              alt="ccms"
              className="w-full h-full object-cover"
            />
          </button>
        </div>
        <div className="max-w-screen-sm md:mt-8 hidden lg:grid mt-6">
          <TextNav
            to={nemaPDF}
            target="_blank"
            title="Download the Brochure for more details"
            withIcon={true}
            color="white"
            className="border-1 border-blue  py-2 px-5 mr-auto bg-transparent"
          >
            Download The Brochure
            <span className="sr-only">Click here to download the Brochure</span>
          </TextNav>
        </div>
      </div>

      <div className="max-w-3xl">
        <h3
          className="text-gold-gradient text-transparent md:block hidden bg-clip-text 
        font-medium xl:max-w-4xl"
        >
          NEMA Mount Street Light Controller
        </h3>
        <span
          className="text-opacity-60 font-normal leading-normal text-base  md:text-lg   
        max-w-md xl:max-w-xl text-white block mt-2 md:mt-4  xl:mt-6  "
        >
          Standard NEMA (7-pin, ANSI c136.41) dimming guarantees swift
          installation and universal luminaire compatibility. NEMA is a perfect
          smart city street lighting solution that is quick and easy to deploy
          throughout your city. A single luminaire controller is suitable for
          single, dual or four-arm poles.
        </span>
        <h5 className="2xl:text-xl text-lg tracking-tight font-light mt-4 md:mt-6 xl:mt-8 2xl:mt-12 mb-4  text-white">
          Redefining its category space
        </h5>
        <div className="flex pt-4 flex-col gap-y-8">
          <div className="flex font-normal flex-col gap-x-4 gap-y-8 text-white lg:flex-row items-start">
            <div className="w-[80%] lg:w-[40%]">
              <ListImage image={Connector} text="7/5 pin NEMA Interface." />
            </div>
            <div className="w-[80%] lg:w-[40%]">
              <ListImage
                image={RemoteOnOff}
                text="Remote ON/OFF of Street Lights."
              />
            </div>
          </div>

          <div className="flex font-normal flex-col gap-x-4 gap-y-8 text-white lg:flex-row items-start">
            <div className="w-[80%] lg:w-[40%]">
              <ListImage image={DimingControl} text="Dimming Control." />
            </div>
            <div className="w-[80%] lg:w-[40%]">
              <ListImage
                image={AlertMonitoring}
                text="Fault and Alert Monitoring."
              />
            </div>
          </div>

          <div className="flex font-normal flex-col gap-x-4 gap-y-8 text-white lg:flex-row items-start">
            <div className="w-[80%] lg:w-[40%]">
              <ListImage image={PowerManagement} text="Power Management." />
            </div>
            <div className="w-[80%] lg:w-[40%]">
              <ListImage image={LightSensor} text="Integrated Light sensor." />
            </div>
          </div>

          <div className="flex font-normal flex-col gap-x-4 gap-y-8 text-white lg:flex-row items-start">
            <div className="w-[80%] lg:w-[40%]">
              <ListImage
                image={Astrological}
                text="Independent and Astronomical schedule based light control."
              />
            </div>
            <div className="w-[80%] lg:w-[40%]">
              <ListImage image={PlugPlay} text="Plug and Play installation." />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
