import React, { useRef, useState } from 'react'
import globe from 'assets/icons/globe.svg'
import dashed_line from 'assets/icons/dashed-line.svg'
import { Image } from 'atoms/image'

import pole_mounted_fv from 'assets/products/pole_mounted-front-view.webp'
import pole_mounted_sv from 'assets/products/retro-fit-street-view.webp'
import big02 from 'assets/products/big02.webp'
import small02 from 'assets/products/small02.webp'
import '../../home/products/style.css'

import RemoteOnOff from 'assets/specs-icons/01/remote-on-off.svg'
import DimingControl from 'assets/specs-icons/01/Diming-control.svg'
import Astrological from 'assets/specs-icons/01/Astronomical schedule.svg'
import GiveAesthetic from 'assets/specs-icons/01/Gives the aesthetic.svg'
import AlertMonitoring from 'assets/specs-icons/01/alert-monitoring.svg'
import PowerManagement from 'assets/specs-icons/01/power-management.svg'
import GroupControl from 'assets/specs-icons/01/Group control.svg'

// import six from 'assets/specs-icons/01/Website icons SVG-06.svg'

import { TextNav } from 'atoms/links'
import retrofitPDF from 'assets/pdf/Retrofit Street Light Controller.pdf'

import pole_mounted_fv_small from 'assets/products/pole-mounted-front-view-small.webp'

import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'
import ListImage from 'atoms/listImage'

export default function RetroFit() {
  const [idx, setIdx] = useState(0)
  const imgRef = useRef(null)

  const exitAnim = (node: any) => {
    gsap.fromTo(
      node,
      { scale: 1, opacity: 1 },
      {
        opacity: 0,
        scale: 0.95,
        duration: 0.5,
      },
    )
  }

  const enter = (node: any) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        scale: 0.95,
      },
      {
        duration: 0.5,
        scale: 1,
        opacity: 1,
      },
    )
  }
  return (
    <div className="w-container lg:blade-top-padding-sm">
      <div className="lg:inline-flex  hidden  w-10 float-left mr-5 pt-1 2xl:pt-3 grow-0 shrink   flex-col justify-center">
        <Image
          src={globe}
          alt="Globe Icon"
          className="select-none h-8 md:h-auto mb-3 md:mb-0  mr-auto"
        />
        <Image
          src={dashed_line}
          alt="Dashed Line"
          className="mx-auto select-none md:block hidden"
        />
      </div>
      <h3
        className="text-gold-gradient text-transparent block md:hidden bg-clip-text
      font-medium mb-4  xl:max-w-2xl"
      >
        Embedded Fixture Mounted Street Light Controller
      </h3>
      <div className=" lg:ml-10 mb-8 w-full lg:sticky top-[200px] grid place-content-center lg:float-right max-w-[520px]">
        <div className="min-w-full">
          <div
            className="bg-white product-card h-80 sm:h-96 !w-full object-cover 
              rounded-lg overflow-hidden"
          >
            <SwitchTransition>
              <Transition
                appear={true}
                timeout={500}
                in={true}
                key={idx}
                onExit={exitAnim}
                onEnter={enter}
                mountOnEnter
                unmountOnExit
              >
                <div className="h-full w-full">
                  <img
                    src={pole_mounted_sv}
                    className={` ${
                      idx === 0 ? '' : 'hidden '
                    } h-full object-center object-cover bg-grey rounded-lg`}
                    alt="left view CCMS product"
                    ref={imgRef}
                  />
                  <img
                    src={pole_mounted_fv}
                    className={` ${
                      idx === 1 ? '' : 'hidden '
                    }  h-full w-full object-center rounded-lg`}
                    alt="left view CCMS product"
                    ref={imgRef}
                  />
                  <img
                    src={big02}
                    className={` ${
                      idx === 2 ? '' : 'hidden '
                    }  h-full w-full object-center rounded-lg`}
                    alt="left view CCMS product"
                    ref={imgRef}
                  />
                </div>
              </Transition>
            </SwitchTransition>
          </div>
        </div>

        <div className="mt-6 md:mt-8 h-20 flex items-center flex-row gap-4">
          <button
            onClick={() => setIdx(0)}
            className={`${
              idx === 0
                ? 'border-[3px] border-solid border-gray border-opacity-60'
                : ''
            } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 focus:outline-none active:outline-none rounded-lg bg-gray bg-opacity-20 flex 
            items-center justify-center`}
            aria-label="thumbnail"
          >
            <Image
              src={pole_mounted_sv}
              alt="ccms"
              className="h-full rounded-lg w-full object-cover"
            />
          </button>
          <button
            onClick={() => setIdx(1)}
            className={` ${
              idx === 1
                ? 'border-[3px] border-solid border-gray border-opacity-60'
                : ''
            } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 focus:outline-none rounded-lg active:outline-none flex items-center justify-center bg-white p-1 md:p-3 overflow-hidden`}
            aria-label="thumbnail"
          >
            <Image
              src={pole_mounted_fv_small}
              alt="ccms"
              className="w-full h-full object-cover"
            />
          </button>
          <button
            onClick={() => setIdx(2)}
            className={` ${
              idx === 2
                ? 'border-[3px] border-solid border-gray border-opacity-60'
                : ''
            } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 focus:outline-none rounded-lg active:outline-none flex items-center justify-center bg-white p-1 md:p-3 overflow-hidden`}
            aria-label="thumbnail"
          >
            <Image
              src={small02}
              alt="ccms"
              className="w-full h-full object-cover"
            />
          </button>
        </div>
        <div className="max-w-screen-sm hidden lg:grid mt-6 md:mt-8">
          <TextNav
            to={retrofitPDF}
            title="Download the Brochure for more details"
            target="_blank"
            withIcon={true}
            color="white"
            className="border-1 border-blue py-2 px-5 mr-auto bg-transparent"
          >
            Download The Brochure
            <span className="sr-only">Click here to download the Brochure</span>
          </TextNav>
        </div>
      </div>

      <div className="max-w-2xl ">
        <h3 className="text-gold-gradient text-transparent md:block hidden bg-clip-text font-medium pt-8 md:pt-0  xl:max-w-2xl">
          Embedded Fixture Mounted Street Light Controller
        </h3>
        <span className=" text-opacity-60 font-normal  text-base  md:text-lg   max-w-md xl:max-w-xl  text-white block mt-2 md:mt-4  xl:mt-6  ">
          ViaLights’ embedded ILC is a wireless street lighting controller for
          monitoring and controlling public lighting fixtures. It creates a
          smart, energy-efficient and safe environment and serves as an ideal
          foundation for Smart City applications. A single luminaire controller
          is suitable for single, dual or four-arm poles.
        </span>
        <h5 className="2xl:text-xl text-lg tracking-tight font-light mt-4 md:mt-6 xl:mt-8 2xl:mt-12 mb-4  text-white">
          Redefining its category space
        </h5>
        <div className="flex pt-4 flex-col gap-y-8">
          <div className="flex font-normal justify-between flex-col gap-x-4 gap-y-8 text-white lg:flex-row items-start">
            <div className="w-[80%] lg:w-[40%]">
              <ListImage
                image={GiveAesthetic}
                text="Gives the aesthetic look of Street light. No device displays outside."
              />
            </div>
            <div className="w-[80%] lg:w-[50%]">
              <ListImage
                image={RemoteOnOff}
                text="Remote ON/OFF of Street Lights."
              />
            </div>
          </div>

          <div className="flex font-normal flex-col gap-x-4 gap-y-8 text-white lg:flex-row items-start">
            <div className="w-[80%] lg:w-[50%]">
              <ListImage image={DimingControl} text="Dimming Control." />
            </div>
            <div className="w-[80%] lg:w-[50%]">
              <ListImage
                image={AlertMonitoring}
                text="Fault and Alert Monitoring."
              />
            </div>
          </div>

          <div className="flex font-normal flex-col gap-x-4 gap-y-8 text-white lg:flex-row items-start">
            <div className="w-[80%] lg:w-[50%]">
              <ListImage image={PowerManagement} text="Power Management." />
            </div>
            <div className="w-[80%] lg:w-[50%]">
              <ListImage image={GroupControl} text="Group control." />
            </div>
          </div>

          <div className="flex font-normal flex-col gap-x-4 gap-y-8 text-white lg:flex-row items-start">
            <div className="w-[80%] lg:w-[50%]">
              <ListImage
                image={Astrological}
                text="Independent and Astronomical schedule based light control."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
