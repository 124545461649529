import React, { useRef, useState } from 'react'
// import pdfFile from 'assets/pdf/LMS_User_Guide.pdf'

import lorawan_sv from 'assets/products/lms-software/LMS-IMG-01.png'
import lorawan_fv from 'assets/products/lms-software/LMS-IMG-02.png'
import lorawan_lv from 'assets/products/lms-software/LMS-IMG-03.png'
import lorawan_rv from 'assets/products/lms-software/LMS-IMG-04.png'
import lorawan_wv from 'assets/products/lms-software/LMS-IMG-06.png'

import lorawan_sv_small from 'assets/products/lms-software/Small/LMS-IMG-small-01.png'
import lorawan_fv_small from 'assets/products/lms-software/Small/LMS-IMG-small-02.png'
import lorawan_lv_small from 'assets/products/lms-software/Small/LMS-IMG-small-03.png'
import lorawan_rv_small from 'assets/products/lms-software/Small/LMS-IMG-small-04.png'
// import lorawan_sv_small from 'assets/products/lorawan-street-view_small.webp'
import gatewayBrochure from 'assets/pdf/lorawan_brochure.pdf'

import one from 'assets/specs-icons/04/icons-01.svg'
import two from 'assets/specs-icons/04/icons-02.svg'
import three from 'assets/specs-icons/04/icons-03.svg'
import four from 'assets/specs-icons/04/icons-04.svg'
import five from 'assets/specs-icons/04/icons-05.svg'
import six from 'assets/specs-icons/04/icons-06.svg'
import seven from 'assets/specs-icons/04/icons-07.svg'

import { Image } from 'atoms/image'
import { MasterNav } from 'atoms/links'
import globe from 'assets/icons/globe.svg'
import dashed_line from 'assets/icons/dashed-line.svg'
import StatisticsTable from 'organisms/statisticsTable'
import gatewayData from 'static/gatewayTable.json'
import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'
import ListImage from 'atoms/listImage'

const screenName = [
  'Live Data Feed',
  'KPI Reports',
  'Device Control',
  'Device Burn Hour',
  'Dashboard',
]

export default function SoftwareDashboard() {
  const [idx, setIdx] = useState(0)
  const imgRef = useRef(null)

  const exitAnim = (node: any) => {
    gsap.fromTo(
      node,
      { scale: 1, opacity: 1 },
      {
        opacity: 0,
        scale: 0.95,
        duration: 0.5,
      },
    )
  }

  const enter = (node: any) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        scale: 0.95,
      },
      {
        duration: 0.5,
        scale: 1,
        opacity: 1,
      },
    )
  }

  return (
    <div id="software" className="blade-top-padding-sm lg:blade-top-padding">
      <div className="bg-no-repeat bg-cover bg-center w-container lg:px-10">
        {/* Mobile View */}
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="500"
          data-aos-once="false"
          className="basis-10 md:hidden pt-1 2xl:pt-3 grow-0 shrink
          pb-5 flex flex-col"
        >
          <Image
            src={globe}
            alt="Globe Icon"
            className="select-none h-8 md:h-auto mb-3 md:mb-0  mr-auto"
          />

          <h3
            className="text-blue-gradient text-transparent block md:hidden bg-clip-text 
          font-semibold"
          >
            Lighting Management Software
          </h3>
        </div>
        <div
          className="flex-col-reverse lg:flex-row gap-x-10 relative 
        flex"
        >
          <div className="basis-96 md:pt-6 lg:pt-0 grow shrink flex flex-col md:flex-row flex-nowrap">
            <div
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="false"
              className="basis-10 pt-1 2xl:pt-3 grow-0 shrink  md:flex hidden flex-col"
            >
              <Image
                src={globe}
                alt="Globe Icon"
                className="select-none h-8 md:h-auto mb-3 md:mb-0  mr-auto"
              />
              <Image
                src={dashed_line}
                alt="Dashed Line"
                className="mx-auto select-none md:block hidden"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="false"
              className="flex-1 md:pl-3 lg:pl-7 shrink max-w-xl flex flex-col gap-4 
              flex-nowrap"
            >
              <h3 className="text-blue-gradient text-transparent md:block hidden bg-clip-text font-semibold">
                Lighting Management Software
              </h3>
              <span className="text-black text-opacity-60 font-normal  text-base  md:text-lg   max-w-md xl:max-w-xl">
                ViaLights’ Lighting Management Software is a cloud-based
                dashboard that ensures municipalities complete control and
                visibility of the entire street light infrastructure.
              </span>

              <div className="flex-1 hidden lg:flex shrink max-w-2xl flex-col gap-4 flex-nowrap">
                <div
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-duration="500"
                  data-aos-once="false"
                  className=" mt-3 md:mt-4 flex-0 lg:flex-1 flex flex-col items-start
                   gap-y-8"
                >
                  <div className="flex justify-between items-start gap-x-6">
                    <div className="w-[45%]">
                      <ListImage
                        image={one}
                        text="Provides remote access for real-time status of lamps."
                      />
                    </div>
                    <div className="w-[45%]">
                      <ListImage
                        image={two}
                        text=" Monitors energy consumption for street lights
                              individually as well as in groups/zones."
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-start gap-x-6">
                    <div className="w-[45%]">
                      <ListImage
                        image={five}
                        text="Live map view of the street lighting network."
                      />
                    </div>
                    <div className="w-[45%]">
                      <ListImage
                        image={three}
                        text="Generates comprehensive energy reports."
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-start gap-x-6">
                    <div className="w-[45%]">
                      <ListImage image={six} text="Manual device operation." />
                    </div>
                    <div className="w-[45%] font-normal">
                      <ListImage
                        image={seven}
                        text="Web API for 3rd party head-end system integration."
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Mobile View  */}
              <div className="flex-1 lg:hidden shrink max-w-2xl flex flex-col gap-4 flex-nowrap">
                <div className="flex-1 flex lg:hidden flex-col items-start gap-1 gap-y-7">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="500"
                    data-aos-once="false"
                    className=" mt-3 md:mt-4 flex-0 lg:flex-1 flex lg:flex-row flex-col items-start gap-y-2  lg:gap-x-4 
                    xl:gap-x-10"
                  >
                    <ul className="font-normal text-dark flex-0 lg:flex-1  flex flex-col flex-nowrap gap-y-6 list-disc">
                      <div className="w-[75%]">
                        <ListImage
                          image={one}
                          text="Provides remote access for real-time status of lamps."
                        />
                      </div>
                      <div className="w-[75%]">
                        <ListImage
                          image={two}
                          text=" Monitors energy consumption for street lights
                              individually as well as in groups/zones."
                        />
                      </div>
                      <div className="w-[75%]">
                        <ListImage
                          image={three}
                          text="Generates comprehensive energy reports."
                        />
                      </div>
                    </ul>

                    <ul className=" font-normal mt-6 text-dark   flex-0 list-disc flex flex-col flex-nowrap gap-y-6 ">
                      <div className="w-[75%]">
                        <ListImage
                          image={five}
                          text="Live map view of the street lighting network."
                        />
                      </div>
                      <div className="w-[75%]">
                        <ListImage
                          image={six}
                          text="Manual device operation."
                        />
                      </div>
                      <div className="w-[75%]">
                        <ListImage
                          image={seven}
                          text="Web API for 3rd party head-end system integration."
                        />
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
            data-aos-once="false"
            className="mb-4 w-full md:px-2 h-fit lg:sticky top-0 xl:top-[200px] max-w-[500px] overflow-hidden grow shrink "
          >
            <div className="bg-darkGray h-80 md:h-96 w-full object-cover rounded-lg overflow-hidden">
              <SwitchTransition>
                <Transition
                  appear={true}
                  timeout={500}
                  in={true}
                  key={idx}
                  onExit={exitAnim}
                  onEnter={enter}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="h-80 relative md:h-96 w-full">
                    <h5
                      className="absolute font-normal text-center top-4 left-0 right-0 z-10 
                    text-white"
                    >
                      {screenName[idx]}
                    </h5>
                    <img
                      src={lorawan_sv}
                      className={` ${
                        idx === 0 ? '' : 'hidden '
                      }  h-full w-full object-center object-cover`}
                      alt="front view loranwan gatway product"
                      ref={imgRef}
                    />
                    <img
                      src={lorawan_fv}
                      className={` ${
                        idx === 1 ? '' : 'hidden '
                      }   h-full w-full object-center object-cover `}
                      alt="left view loranwan gatway product"
                      ref={imgRef}
                    />

                    <img
                      src={lorawan_lv}
                      className={` ${
                        idx === 2 ? '' : 'hidden '
                      }   h-full w-full object-center object-cover `}
                      alt="right view loranwan gatway product"
                      ref={imgRef}
                    />
                    <img
                      src={lorawan_rv}
                      className={` ${
                        idx === 3 ? '' : 'hidden '
                      }  h-full w-full object-cover  `}
                      alt="right view loranwan gatway product"
                      ref={imgRef}
                    />
                    <img
                      src={lorawan_wv}
                      className={` ${
                        idx === 4 ? '' : 'hidden '
                      }  h-full w-full object-cover  `}
                      alt="right view loranwan gatway product"
                      ref={imgRef}
                    />
                  </div>
                </Transition>
              </SwitchTransition>
            </div>

            <div className="mt-8 h-20 flex flex-row gap-2 md:gap-4">
              <button
                onClick={() => setIdx(0)}
                className={` ${
                  idx === 0
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 active:outline-none focus:outline-none sm:w-20 md:h-20 
                md:w-24 xl:w-28 bg-gray bg-opacity-20 flex items-center justify-center 
                overflow-hidden rounded-lg`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_sv_small}
                  alt="lorawan"
                  className="!h-full !w-full rounded-lg"
                />
              </button>

              <button
                onClick={() => setIdx(1)}
                className={` ${
                  idx === 1
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 focus:outline-none active:outline-none flex items-center 
                justify-center bg-gray bg-opacity-20 rounded-lg overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_fv_small}
                  alt="lorawan"
                  className="!h-full !w-full rounded-lg"
                />
              </button>

              <button
                onClick={() => setIdx(2)}
                className={` ${
                  idx === 2
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 focus:outline-none active:outline-none flex items-center 
                justify-center bg-gray bg-opacity-20 rounded-lg overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_lv_small}
                  alt="lorawan"
                  className="!h-full !w-full rounded-lg"
                />
              </button>
              <button
                onClick={() => setIdx(3)}
                className={` ${
                  idx === 3
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 focus:outline-none active:outline-none flex items-center 
                justify-center bg-gray bg-opacity-20 rounded-lg overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_rv_small}
                  alt="lorawan"
                  className="!h-full !w-full rounded-lg"
                />
              </button>

              <button
                onClick={() => setIdx(4)}
                className={` ${
                  idx === 3
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 focus:outline-none active:outline-none flex items-center 
                justify-center bg-gray bg-opacity-20 rounded-lg overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_wv}
                  alt="lorawan"
                  className="!h-full !w-full rounded-lg"
                />
              </button>
            </div>
            {/* <div className="max-w-2xl hidden lg:grid mt-8">
              <MasterNav
                to={pdfFile}
                title="Download the Brochure for more details"
                withIcon={true}
                showRightChevron={false}
                className="border-1  border-blue py-2 px-5 mr-auto "
              >
                <span className="sr-only">
                  Click here to download the Brochure
                </span>
                Download The Brochure
              </MasterNav>
            </div> */}
          </div>
        </div>
        {/* <div className="max-w-2xl grid lg:hidden mt-8 mx-auto place-content-center">
          <MasterNav
            to={pdfFile}
            title="Download the Brochure for more details"
            withIcon={true}
            showRightChevron={false}
            className="border-1 border-blue py-2 px-5 mr-auto "
          >
            <span className="sr-only">Click here to download the Brochure</span>
            Download The Brochure
          </MasterNav>
        </div> */}
      </div>
    </div>
  )
}
