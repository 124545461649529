import React, { Fragment } from 'react'
import bannerBg from 'assets/home/banner_video.mp4'
import ScrollDown from 'atoms/scrollDown'

export default function Banner() {
  return (
    <Fragment key="home_banner">
      <div className="lg:h-screen min-h-[700px] relative md:bg-black home-banner-gradient pt-24 md:pt-36 lg:pt-44 banner">
        <div className="absolute md:left-0 backdrop-blur-2xl lg:top-0 bottom-0 lg:-right-10 -right-0 z-0">
          <video
            muted
            autoPlay
            loop
            className="z-0 lg:h-full h-[24rem] w-full object-cover object-right"
            playsInline
          >
            <source src={bannerBg} />
          </video>
        </div>
        <div
          className="absolute top-[100px] md:top-0 left-0 md:bottom-0 right-0 flex flex-col justify-around "
          data-aos="fade"
          data-aos-delay="200"
          data-aos-duration="500"
          data-aos-once="false"
        >
          <div className="w-container py-16 md:py-20 xl:py-44">
            <h1
              data-aos-anchor-placement="top-center"
              data-aos-duration="700"
              className="target-text text-gold-gradient text-transparent bg-clip-text md:text-left text-center leading-normal 
              font-light"
            >
              Bringing New <br className="md:block hidden" />
              <strong className="font-medium">Perspectives</strong>
              <br className="lg:block hidden" /> To Light
            </h1>
          </div>
          <div className="h-20 w-container md:block hidden">
            <ScrollDown />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
