import { TextNav } from 'atoms/links'
import React, { Fragment, useRef, useState } from 'react'

import { Image } from 'atoms/image'

import ccms_fv from 'assets/products/ccms-front-view.webp'
import ccms_lv from 'assets/products/ccms-left-view.webp'
import ccms_rv from 'assets/products/ccms-right-view.webp'
import ccms_sv from 'assets/products/ccms-street-view.jpg'
import ccms_sv_small from 'assets/products/ccms-street-view-small.jpg'
import ccms_fv_small from 'assets/products/ccms-front-view_small.png'
import ccms_lv_small from 'assets/products/ccms-left-view_small.png'
import ccms_rv_small from 'assets/products/ccms-right-view_small.png'

import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'

// gsap.registerPlugin(ScrollTrigger)

export default function Features() {
  const [idx, setIdx] = useState(0)
  const imgRef = useRef(null)

  const exitAnim = (node: any) => {
    gsap.fromTo(
      node,
      { scale: 1, opacity: 1 },
      {
        opacity: 0,
        scale: 0.95,
        duration: 0.5,
      },
    )
  }

  const enter = (node: any) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        scale: 0.95,
      },
      {
        duration: 0.5,
        scale: 1,
        opacity: 1,
      },
    )
  }

  return (
    <Fragment key="home_products">
      <div>
        <div
          className="w-container lg:h-[600px] 2xl:h-[700px] flex flex-nowrap  md:gap-x-10 lg:gap-x-16 
        xl:gap-x-20 items-start 2xl:items-center overflow-hidden"
        >
          <div className="mockup-illustration -translate-y-56 2xl:-translate-y-4 lg:block hidden relative basis-10 grow-0 shrink ">
            <svg
              // className="-mb-10 2xl:-mb-6"
              width="78"
              height="800"
              viewBox="0 0 78 806"
              fill="none"
              stroke="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.06421 2.75977L2.06425 802.896"
                // stroke="url(#paint0_linear_2168_3356)"
                stroke="#fff"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M2.07837 1.50977L2.27257 236.47C4.62165 249.747 10.8397 279.626 24.8116 293.108C42.2764 309.96 58.8221 342.851 58.8221 370.015C58.8221 391.409 58.1071 473.885 58.8221 512.491C59.0918 527.057 49.0172 558.451 28.182 580.512C7.34669 602.573 2.99757 631.076 2.07837 641.392L2.33124 803.956"
                stroke="url(#paint1_linear_2168_3356)"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2168_3356"
                  x1="0.0214948"
                  y1="1.50977"
                  x2="0.02152"
                  y2="788.716"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" stopOpacity="0.12" />
                  <stop offset="0.556748" stopColor="white" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_2168_3356"
                  x1="1.51632"
                  y1="1.50977"
                  x2="1.51632"
                  y2="803.956"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#D0B05D" stopOpacity="0" />
                  <stop offset="0.5" stopColor="#D0B05D" />
                  <stop offset="1" stopColor="#D0B05D" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
            <div className="h-11 w-11 settings-icon opacity-0 absolute top-[52%] -right-[2px]  rounded-full">
              <svg
                className="animate-spin-slow"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.903965 21.8184V17.1295L6.06336 16.0743C6.06336 15.8391 6.17987 15.7226 6.17987 15.4873L2.31089 12.0866L4.65648 7.98236L9.58063 9.62449C9.69714 9.50798 9.81585 9.38928 9.93236 9.27277L8.29022 4.34861L12.3944 2.00302L15.7952 5.872C16.0304 5.872 16.1469 5.75549 16.3822 5.75549L17.3165 0.714844H22.0054L23.0606 5.87424C23.2958 5.87424 23.4123 5.99074 23.6475 5.99074L27.0483 2.12176L31.1525 4.46735L29.5104 9.39151C29.6269 9.50802 29.7456 9.62673 29.8621 9.74323L34.7862 8.1011L37.1318 12.2053L33.2629 15.6061C33.2629 15.8413 33.3794 15.9578 33.3794 16.193L38.42 17.1273V21.8163L33.2606 22.8714C33.2606 23.1067 33.1441 23.2232 33.1441 23.4584L37.0131 26.8592L34.6675 30.9634L29.7433 29.3212C29.6268 29.4377 29.5081 29.5565 29.3916 29.673L31.0338 34.5971L26.9295 36.9427L23.5288 33.0737C23.2936 33.0737 23.1771 33.1902 22.9418 33.1902L22.0075 38.2309H17.3186L16.2634 33.0715C16.0282 33.0715 15.9117 32.955 15.6765 32.955L12.2757 36.824L8.17148 34.4784L9.81362 29.5542C9.69711 29.4377 9.5784 29.319 9.46189 29.2025L4.53773 30.8446L2.19215 26.7404L6.06113 23.3397C6.06113 23.1044 5.94462 22.9879 5.94462 22.7527L0.903965 21.8184ZM12.6274 19.4728C12.6274 23.3418 15.7929 26.5073 19.6619 26.5073C23.5309 26.5073 26.6964 23.3418 26.6964 19.4728C26.6964 15.6038 23.5309 12.4383 19.6619 12.4383C15.7929 12.4383 12.6274 15.6038 12.6274 19.4728Z"
                  fill="url(#paint0_linear_1834_4045)"
                />
                <circle
                  cx="58.8275"
                  cy="409.149"
                  r="7.3927"
                  fill="#010101"
                ></circle>
                <defs>
                  <linearGradient
                    id="paint0_linear_1834_4045"
                    x1="19.662"
                    y1="0.714844"
                    x2="19.662"
                    y2="38.2309"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFE8AC" />
                    <stop offset="1" stopColor="#D0B05D" />
                    <stop offset="1" stopColor="#D0B05D" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            <div className="appear-wrapper appear-animation absolute bottom-0 border-none w-full animation-gradient"></div>
          </div>
          <div className="basis-96 grow shrink">
            <div className="flex lg:flex-row flex-col-reverse lg:gap-y-10 gap-x-16">
              <div className="lg:basis-96 grow shrink flex flex-col justify-center">
                <h3 className="max-w-2xl text-white text-opacity-90  font-normal">
                  Centralized Control & <br className="lg:block hidden" />
                  Monitoring System
                </h3>
                <h6 className="text-white text-opacity-60  leading-normal font-light xl:max-w-md  max-w-screen-sm mt-4 pr-10 ">
                  Designed indigenously for smart lighting projects, the CCMS
                  offers a complete feeder panel automation for group control
                  and monitoring of street lights.
                </h6>
                <div className="mr-auto grid mt-5 md:mt-10">
                  <TextNav
                    color="white"
                    to="/hardware-offerings#ccms"
                    withBorder={true}
                    withIcon={true}
                    ariaLabel="Know More"
                    title="Know More"
                    rel="author"
                    target="_self"
                    className="py-2 md:py-4 lg:text-base bg-transparent"
                  >
                    Know More
                  </TextNav>
                </div>
              </div>
              <div className="basis-96 grow shrink w-full lg:mx-auto">
                <div className="max-w-[500px] xl:ml-auto">
                  <div className="w-full h-full product-card rounded-xl overflow-hidden ">
                    <SwitchTransition>
                      <Transition
                        appear={true}
                        timeout={500}
                        in={true}
                        key={idx}
                        onExit={exitAnim}
                        onEnter={enter}
                        mountOnEnter
                        unmountOnExit
                      >
                        <div className="h-80 sm:h-96 w-full flex justify-center items-center">
                          <img
                            src={ccms_sv}
                            className={` ${
                              idx === 0 ? '' : 'hidden '
                            } !w-full object-cover object-center !h-full select-none 
                            pointer-events-none  border-1 border-gray rounded-xl`}
                            alt="front view CCMS product"
                            ref={imgRef}
                          />

                          <img
                            src={ccms_lv}
                            className={` ${
                              idx === 1 ? '' : 'hidden '
                            }  h-full w-full object-center object-contain p-4 md:p-7 select-none  pointer-events-none `}
                            alt="left view CCMS product"
                            ref={imgRef}
                          />

                          <img
                            src={ccms_rv}
                            className={` ${
                              idx === 2 ? '' : 'hidden '
                            } pr-4  h-full w-full object-center object-contain p-4 md:p-7 select-none  pointer-events-none `}
                            alt="right view CCMS product"
                            ref={imgRef}
                          />
                          <img
                            src={ccms_fv}
                            className={` ${
                              idx === 3 ? '' : 'hidden '
                            }  h-full w-full object-center object-contain p-4 md:p-7 
                            select-none  pointer-events-none`}
                            alt="right view CCMS product"
                            ref={imgRef}
                          />
                        </div>
                      </Transition>
                    </SwitchTransition>
                  </div>
                </div>
                <div className="mt-8 h-20  max-w-[500px] xl:ml-auto flex flex-row md:justify-between  gap-2 md:gap-4 ">
                  <button
                    onClick={() => setIdx(0)}
                    className={` ${
                      idx === 0
                        ? 'border-[3px] border-solid border-gray border-opacity-60'
                        : ''
                    } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 bg-gray bg-opacity-20 rounded-lg flex 
                    items-center justify-center overflow-hidden`}
                    aria-label="thumbnail"
                  >
                    <Image
                      src={ccms_sv}
                      alt="ccms"
                      className="object-cover h-full !w-full"
                    />
                  </button>
                  <button
                    onClick={() => setIdx(1)}
                    className={` ${
                      idx === 1
                        ? 'border-[3px] border-solid border-gray border-opacity-60'
                        : ''
                    }  h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 focus:outline-none active:outline-none flex rounded-lg items-center 
                    justify-center bg-white p-1 md:p-3 overflow-hidden`}
                    aria-label="thumbnail"
                  >
                    <Image
                      src={ccms_fv_small}
                      alt="ccms"
                      className="w-full h-full"
                    />
                  </button>

                  <button
                    onClick={() => setIdx(2)}
                    className={` ${
                      idx === 2
                        ? 'border-[3px] border-solid border-gray border-opacity-60'
                        : ''
                    }  h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 flex items-center rounded-lg justify-center bg-white p-1 md:p-3 overflow-hidden`}
                    aria-label="thumbnail"
                  >
                    <Image
                      src={ccms_lv_small}
                      alt="ccms"
                      className="w-full h-full"
                    />
                  </button>

                  <button
                    onClick={() => setIdx(3)}
                    className={` ${
                      idx === 3
                        ? 'border-[3px] border-solid border-gray border-opacity-60'
                        : ''
                    }  h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 flex items-center rounded-lg justify-center bg-white p-1 md:p-3 
                    overflow-hidden`}
                    aria-label="thumbnail"
                  >
                    <Image
                      src={ccms_rv_small}
                      alt="ccms"
                      className="w-full h-full"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div
            className="w-container pt-11 lg:pt-0 lg:h-[300px] 2xl:h-[400px] product-flex-card flex flex-nowrap 
            gap-x-6 md:gap-x-10 lg:gap-x-16 xl:gap-x-20 items-center"
          >
            <div className="mockup-illustration lg:block hidden relative basis-10 grow-0 shrink">
              <svg
                className=""
                width="78"
                height="800"
                viewBox="0 0 78 806"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.06421 2.75977L2.06425 802.896"
                  // stroke="url(#paint0_linear_2170_3361)"
                  stroke="#fff"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <path
                  d="M2.07837 1.50977L2.27257 236.47C4.62165 249.747 10.8397 279.626 24.8116 293.108C42.2764 309.96 58.8221 342.851 58.8221 370.015C58.8221 391.409 58.1071 473.885 58.8221 512.491C59.0918 527.057 49.0172 558.451 28.182 580.512C7.34669 602.573 2.99757 631.076 2.07837 641.392L2.33124 803.956"
                  stroke="url(#paint1_linear_2170_3361)"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2170_3361"
                    x1="0.0214948"
                    y1="1.50977"
                    x2="0.02152"
                    y2="788.716"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0.12" />
                    <stop offset="0.556748" stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2170_3361"
                    x1="1.51632"
                    y1="1.50977"
                    x2="1.51632"
                    y2="803.956"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3661FF" stopOpacity="0" />
                    <stop offset="0.5" stopColor="#3661FF" />
                    <stop offset="1" stopColor="#3661FF" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>

              <div className="h-11 w-11 settings-icon opacity-0 absolute top-[52%] -right-[2px]  rounded-full">
                <svg
                  className="animate-spin-slow"
                  viewBox="0 0 39 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M37.9925 21.9551V17.2662L32.8331 16.211C32.8331 15.9758 32.7166 15.8593 32.7166 15.6241L36.5856 12.2233L34.24 8.11908L29.3159 9.76121C29.1993 9.6447 29.0806 9.52599 28.9641 9.40949L30.6063 4.48533L26.502 2.13974L23.1013 6.00872C22.8661 6.00872 22.7496 5.89221 22.5143 5.89221L21.58 0.851562H16.8911L15.8359 6.01095C15.6007 6.01095 15.4842 6.12746 15.249 6.12746L11.8482 2.25848L7.74398 4.60407L9.38612 9.52823C9.26961 9.64474 9.1509 9.76344 9.03439 9.87995L4.11024 8.23782L1.76465 12.342L5.63363 15.7428C5.63363 15.978 5.51712 16.0945 5.51712 16.3298L0.476471 17.2641V21.953L5.63586 23.0082C5.63586 23.2434 5.75237 23.3599 5.75237 23.5951L1.88339 26.9959L4.22898 31.1001L9.15314 29.458C9.26965 29.5745 9.38835 29.6932 9.50486 29.8097L7.86273 34.7338L11.9669 37.0794L15.3677 33.2104C15.6029 33.2104 15.7194 33.327 15.9547 33.327L16.889 38.3676H21.5779L22.6331 33.2082C22.8683 33.2082 22.9848 33.0917 23.22 33.0917L26.6208 36.9607L30.725 34.6151L29.0829 29.6909C29.1994 29.5744 29.3181 29.4557 29.4346 29.3392L34.3588 30.9814L36.7043 26.8771L32.8354 23.4764C32.8354 23.2412 32.9519 23.1246 32.9519 22.8894L37.9925 21.9551ZM26.2691 19.6095C26.2691 23.4785 23.1035 26.644 19.2346 26.644C15.3656 26.644 12.2001 23.4785 12.2001 19.6095C12.2001 15.7405 15.3656 12.575 19.2346 12.575C23.1035 12.575 26.2691 15.7405 26.2691 19.6095Z"
                    fill="url(#paint0_linear_1834_3991)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1834_3991"
                      x1="19.2345"
                      y1="0.851562"
                      x2="19.2345"
                      y2="38.3676"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3661FF" />
                      <stop offset="1" stopColor="#3661FF" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>

              <div className="appear-wrapper appear-animatio absolute bottom-0 border-none w-full animation-gradient"></div>
            </div>
            <div className="basis-96 grow shrink">
              <div className="3xl:max-w-2xl 2xl:max-w-xl max-w-md ">
                <div>
                  <h3 className="max-w-5xl text-white text-opacity-90 font-normal">
                    Individual Street Light Controllers
                  </h3>
                  <h6 className="text-white text-opacity-60 font-light leading-normal max-w-xl mt-4 pr-10 ">
                    Suite of industry-standard smart street light controllers
                    providing energy efficiency in the smart cities of tomorrow.
                  </h6>
                  <div className="mr-auto grid mt-5 md:mt-10">
                    <TextNav
                      color="white"
                      to="/hardware-offerings#controllers"
                      withBorder={true}
                      withIcon={true}
                      ariaLabel="Know More"
                      title="Know More"
                      rel="author"
                      target="_self"
                      className="py-2 md:py-4  lg:text-base bg-transparent "
                    >
                      Know More
                    </TextNav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
