interface TableProp {
  parameter: string
  stat: string
}
export default function StatisticsTable({
  viewData,
}: {
  viewData: Array<TableProp>
}) {
  return (
    <div
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-duration="500"
      data-aos-once="false"
      className=" my-4 md:my-6 lg:mt-10 mb-3 px-1 w-full"
    >
      <table
        rules="all"
        className="whitespace-pre-wrap bg-darkGray text-white font-normal overflow-x-auto   w-full lg:w-auto rounded-md  md:rounded-lg  overflow-hidden   md:mx-auto     "
      >
        <tbody>
          {viewData.map((elem, index) => {
            const { parameter, stat } = elem
            return (
              <tr key={index} className="">
                <td className="p-2 md:p-4 xl:p-6 text-white text-opacity-60 font-light">
                  <span>{parameter}</span>
                  <span className="block h-full lg:hidden font-light  text-opacity-30 pt-1 text-sm md:text-base text-white max-w-md">
                    {stat}
                  </span>
                </td>
                <td className="h-full px-4 md:px-4 lg:px-6 xl:px-6 py-6 font-light lg:block hidden max-w-md">
                  {stat}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
