import { MasterNav } from 'atoms/links'
import { useEffect } from 'react'

export default function ComingSoon() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className="h-screen bg-no-repeat bg-contain bg-right-bottom bg-blue-gradient text-center gap-1  xl:gap-4">
      <div className=" px-4  grid place-content-center place-items-center  banner pt-20">
        <h1 className="text-gold-gradient text-transparent bg-clip-text font-sequel-bold">
          Coming Soon
        </h1>
        <h6 className="text-white font-sequel-regular  ">
          Page you are looking for is under development{' '}
          <br className="md:block hidden" />
          and will be live soon.
        </h6>
        <MasterNav
          to="/"
          withIcon={false}
          showRightChevron={false}
          className=" px-6 py-2 mt-10 hover:bg-blue  hover:text-white transition-colors duration-300 ease-in-out  "
          target="_self"
        >
          Return to Home
        </MasterNav>
      </div>
    </div>
  )
}
