import React, { Fragment } from 'react'
import { Image } from 'atoms/image'
import softwareIcon from 'assets/icons/software-services.svg'
import { TextNav } from 'atoms/links'
import dashboard from 'assets/globals/dashboard.png'
import mobileApp from 'assets/globals/mobileApp.png'
export default function SoftwareServices() {
  const OfferingCard = ({
    title,
    desc,
    target,
    coverImg,
  }: {
    title: string
    desc: string
    target: string
    coverImg: string
  }) => {
    return (
      <article
        className="px-1 lg:px-3  pr-1 lg:pr-3 l pt-1 lg:pt-3 mx-auto group h-full max-w-xl grow-0
       bg-white rounded-lg shrink shadow-md flex flex-col flex-nowrap"
      >
        <div className="basis-72 overflow-hidden md:basis-80 rounded-t-lg grow-0 shrink-0 ">
          <img
            src={coverImg}
            alt={title}
            className="h-full w-full scale-[1.01] group-hover:scale-105 transition-all duration-300 ease-in-out object-cover object-center"
          />
        </div>
        <h4 className="font-medium pt-6 pb-2 px-3 group-hover:text-blue  transition-colors duration-200 ease-in-out">
          {title}
        </h4>
        <span className="text-sm sm:text-base md:text-lg font-normal leading-normal px-3 text-dark text-opacity-70 pr-6 flex-1 ">
          {desc}
        </span>
        <div className="grid mt-4  md:mt-8  mb-3  md:mb-6 px-3 mr-auto ">
          <TextNav
            withIcon={true}
            to={target}
            color="dark"
            ariaLabel="Know More"
            className="font-normal"
          >
            Know More
          </TextNav>
        </div>
      </article>
    )
  }
  return (
    <Fragment key="software-services">
      <section className=" lg:blade-bottom-padding blade-bottom-padding-sm relative">
        <section
          className="w-container -mt-1 md:bg-software-services blade-bottom-padding-sm lg:blade-bottom-padding
         lg:pt-0 blade-top-padding-sm"
        >
          <div data-aos="fade-up" data-aos-duration="500" data-aos-once="false">
            <div className="flex flex-nowrap items-center gap-3 pb-4 ">
              {/* <Image
                className="pr-4 w-5 d"
                src={softwareIcon}
                alt="blogs"
                title="Blogs"
              /> */}

              <svg
                className="w-[26px]"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1505_3353)">
                  <path
                    d="M15.6846 0.956055C7.46817 0.956055 0.745178 7.67904 0.745178 15.8954C0.745178 24.1118 7.46817 30.8348 15.6846 30.8348C23.9009 30.8348 30.6239 24.1118 30.6239 15.8954C30.6239 7.67904 23.9009 0.956055 15.6846 0.956055V0.956055ZM26.7968 14.4948H23.0624C22.875 12.5339 22.0346 8.79954 19.4207 5.34348C23.3407 6.7441 26.2365 10.2929 26.7968 14.4948ZM16.6177 4.97057C19.8864 8.33207 20.9123 12.4412 21.1925 14.4948H16.6177V4.97057ZM14.7514 14.4948H10.1766C10.4567 12.4412 11.3899 8.33207 14.7514 4.97057V14.4948ZM14.7514 17.2961V26.8203C11.4826 23.4588 10.4567 19.3497 10.1766 17.2961H14.7514ZM16.6177 17.2961H21.1925C20.9123 19.3497 19.9792 23.4588 16.6177 26.8203V17.2961ZM11.9502 5.34348C9.33626 8.79775 8.58866 12.5339 8.30854 14.4948H4.57414C5.13264 10.2929 8.02841 6.7441 11.9502 5.34348ZM4.57235 17.2961H8.30675C8.49408 19.2569 9.33447 22.9913 11.9484 26.4474C8.02837 25.0468 5.1326 21.4979 4.57235 17.2961V17.2961ZM19.4189 26.4474C22.0328 22.9931 22.7804 19.2569 23.0606 17.2961H26.795C26.2365 21.4979 23.3407 25.0468 19.4189 26.4474V26.4474Z"
                    fill="#3661FF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1505_3353">
                    <rect
                      width="30.4775"
                      height="30.4775"
                      fill="white"
                      transform="translate(0.745178 0.956055)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <h5 className="text-white font-normal">Software Services</h5>
            </div>
            <h2 className="text-white font-light text-opacity-70 inline ">
              Innovative SaaS offerings <br className="md:block hidden" />{' '}
              ensuring live visibility of a{' '}
            </h2>
            <h2
              data-aos-anchor-placement="top-center"
              data-aos-duration="700"
              className="capitalize text-gold-gradient text-transparent bg-clip-text font-medium inline lg:block "
            >
              smart street light <br className="md:block hidden" /> ecosystem.
            </h2>
          </div>
        </section>
        <div className="max-w-7xl lg:grid hidden  absolute  justify-center  lg:-bottom-[30rem] xl:-bottom-[28rem] 2xl:-bottom-80 top-auto left-0 right-0  blade-top-padding  mx-auto md:w-10/12  md:grid-cols-2 grid-cols-1 gap-5">
          <div data-aos="fade-up" data-aos-duration="500" data-aos-once="false">
            <OfferingCard
              title="Lighting Management Software"
              desc="Vialights’ Lighting Management Software is a cloud-based dashboard providing complete control, accessibility, and a live map view of the entire smart street light infrastructure. It gives you full command over your entire public lighting infrastructure."
              target="software-services/#software"
              coverImg={dashboard}
            />
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
            data-aos-once="false"
          >
            <OfferingCard
              title="LMS Mobile Application"
              desc="The LMS mobile application keeps municipalities well-informed about the real-time operational whereabouts of streetlights."
              target="/software-services#application"
              coverImg={mobileApp}
            />
          </div>
        </div>
        {/* Mobile View */}
        <div className=" px-3  max-w-7xl grid  lg:hidden justify-center mx-auto md:w-10/12  md:grid-cols-2 grid-cols-1 gap-5 ">
          <OfferingCard
            title="Lighting Management Software"
            desc="ViaLights’ Lighting Management Software is a cloud-based dashboard providing complete control, accessibility, and a live map view of the entire smart street light infrastructure."
            target="software-services/#software"
            coverImg={dashboard}
          />
          <OfferingCard
            title="LMS Mobile Application"
            desc="The LMS Mobile Application keeps municipalities well-informed about the real-time operational whereabouts of street lights and other linked technologies."
            target="software-services/#application"
            coverImg={mobileApp}
          />
        </div>
      </section>
    </Fragment>
  )
}
