import { useEffect } from 'react'
import Banner from './banner'
import Gateway from './gateway'
import CCMSController from './ccmsControllers'
import Controller from './controller'
export default function Product() {
  const urlHash = window.location.hash
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
    if (urlHash.length) {
      const element = document.getElementById(urlHash.substring(1))
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [urlHash])
  return (
    <>
      <Banner />
      <CCMSController />
      <Controller />
      <Gateway />
    </>
  )
}
