import { useEffect } from 'react'
import Application from './application'
import Banner from './banner'
import SoftwareApplication from './software-application'
import SoftwareDashboard from './software-dashboard'

export default function SoftwareServices() {
  const urlHash = window.location.hash
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
    if (urlHash.length) {
      const element = document.getElementById(urlHash.substring(1))
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [urlHash])

  return (
    <div>
      <Banner />
      <div className="">
        <SoftwareDashboard />
        <SoftwareApplication />
        {/* <Software />
        <Application /> */}
      </div>
    </div>
  )
}
