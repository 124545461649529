import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import ReCaptchaV2 from 'react-google-recaptcha'
import emailjs from '@emailjs/browser'

interface FormFields {
  email: string
  mobile: string
  name: string
  message: string
}

export default function Form() {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isVerified, setVerified] = useState(false)
  const [token, setToken] = useState(null)
  const initalFormState: FormFields = {
    email: '',
    name: '',
    mobile: '',
    message: '',
  }

  let captchaRef = useRef<any>()

  const templateID = process.env.REACT_APP_TEMPLATE_ID ?? ''
  const serviceID = process.env.REACT_APP_SERVICE_ID ?? ''
  const publicKey = process.env.REACT_APP_PUBLIC_KEY ?? ''
  const verificationKey = process.env.REACT_APP_CAPTCHA_KEY ?? ''

  const submitHandler = (values: FormFields, { resetForm }: any) => {
    const formPayload = Object.assign(values, { token: token }) as any
    setLoading(true)
    try {
      emailjs
        .send(serviceID, templateID, formPayload, publicKey)
        .then(
          function (response: any) {
            setLoading(false)
            resetForm()
            captchaRef.current.reset()
            setToken(null)
            setVerified(false)
            toast.success('Form has been submitted successfully.')
          },
          function (err: any) {
            setLoading(false)
            resetForm()
            captchaRef.current.reset()
            setToken(null)
            setVerified(false)
            toast.error('Error occured. Please try again.')
          },
        )
        .catch(() => {
          resetForm()
          captchaRef.current.reset()
          setToken(null)
          setVerified(false)
          toast.error('Failed to submit the form.')
        })
    } catch (err) {
      resetForm()
      captchaRef.current.reset()
      setToken(null)
      setVerified(false)
      toast.error('failed to submit the form.')
    }
  }
  const validator = (values: FormFields) => {
    const errors: any = {}
    if (!values.email) {
      errors.email = 'Please Enter Your Email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid Email Address'
    }
    if (values.mobile === '') {
      errors.mobile = 'Please Enter Your Phone Number'
    }

    if ((values.mobile + '').length !== 10) {
      errors.mobile = 'Please Enter Valid Phone Number'
    }
    if (values.name === '') {
      errors.name = 'Please Enter Your Name'
    }
    return errors
  }

  const handleToken = (token: any) => {
    setToken(token)
    setVerified(true)
  }
  const handleExpire = () => {
    setToken(null)
    setVerified(false)
  }

  return (
    <Formik
      initialValues={initalFormState}
      validate={(values) => validator(values)}
      onSubmit={(values, actions) => submitHandler(values, actions)}
      validateOnChange={false}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          noValidate
          className="bg-transparent grid  gap-1"
        >
          <div className="h-28">
            <label
              className="mb-2 flex flex-col gap-1  font-light text-base md:text-lg text-white"
              htmlFor="name"
            >
              Full Name*
            </label>
            <input
              size={1}
              name="name"
              id="name"
              aria-label="Full Name"
              type="text"
              className={`${
                errors.name ? 'border-danger' : ' border-gray'
              } border-1  border-gray border-opacity-60 focus:border-blue rounded-xl placeholder:text-gray bg-transparent outline-none focus:outline-none     px-3 md:px-4 py-3  md:py-4 font-normal text-white w-full `}
              placeholder="Enter Full Name"
              value={values.name}
              onChange={handleChange}
            />
            {errors.name && (
              <small className=" text-danger text-opacity-80 dark:text-danger font-light">
                {errors.name}
              </small>
            )}
          </div>
          <div className="h-28">
            <label
              className="mb-2 flex flex-col gap-1  font-light text-base md:text-lg text-white"
              htmlFor="email"
            >
              Enter Email*
            </label>
            <input
              size={1}
              name="email"
              id="email"
              aria-label="Enter Email"
              type="email"
              className={`${
                errors.name ? 'border-danger' : ' border-gray'
              } border-1  border-gray border-opacity-60 focus:border-blue  rounded-xl placeholder:text-gray bg-transparent outline-none focus:outline-none      px-3 md:px-4 py-3  md:py-4 font-normal text-white w-full `}
              placeholder="Enter Email"
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && (
              <small className=" text-danger text-opacity-80 dark:text-danger font-light">
                {errors.email}
              </small>
            )}
          </div>
          <div className="h-28">
            <label
              className="mb-2 flex flex-col gap-1  font-light text-base md:text-lg text-white"
              htmlFor="mobile"
            >
              Phone Number*
            </label>
            <input
              size={1}
              name="mobile"
              type="number"
              min="0"
              inputMode="numeric"
              pattern="[0-9]*"
              id="mobile"
              aria-label="Phone Number"
              className={`${
                errors.name ? 'border-danger' : ' border-gray'
              } border-1  focus:border-blue rounded-xl placeholder:text-gray bg-transparent outline-none focus:outline-none     px-3 md:px-4 py-3  md:py-4 font-normal text-white w-full `}
              placeholder="Enter Phone Number"
              value={values.mobile}
              onChange={handleChange}
            />
            {errors.mobile && (
              <small className=" text-danger text-opacity-80 dark:text-danger font-light">
                {errors.mobile}
              </small>
            )}
          </div>
          <div>
            <label
              className="mb-2 flex flex-col gap-1  font-light text-base md:text-lg text-white"
              htmlFor="mobile"
            >
              Message
            </label>
            <textarea
              cols={7}
              rows={5}
              name="message"
              id="messsage"
              aria-label="Message"
              placeholder="Enter Message"
              value={values.message}
              onChange={handleChange}
              className="border-1  border-gray border-opacity-60 focus:border-blue  rounded-xl placeholder:text-gray bg-transparent outline-none focus:outline-none  resize-none    px-3 md:px-4 py-3  md:py-4 font-normal text-white w-full"
            ></textarea>
          </div>
          <div className="captcha-wrapper">
            <ReCaptchaV2
              sitekey={verificationKey}
              ref={captchaRef}
              onChange={handleToken}
              onExpired={handleExpire}
            />
          </div>
          <button
            disabled={isLoading || !isVerified}
            type="submit"
            className=" text-white font-normal disabled:opacity-70  bg-blue px-10  py-3 text rounded-md  text-lg  focus:outline-blue  hover:outline-blue outline-transparent outline-offset-2 outline   disabled:cursor-not-allowed   border-gray  outline-2 md:text-xl  mt-6"
            aria-label="loader"
          >
            {isLoading ? 'Loading' : 'Submit'}
          </button>
        </form>
      )}
    </Formik>
  )
}
