import { useLocation } from 'react-router-dom'
import StatisticsTable from 'organisms/statisticsTable'
import mountStreetLight from 'static/nemaMountedLight.json'
import poleMountStreetLight from 'static/poleMountLight.json'
import retroFitStreetLight from 'static/retroFitLight.json'
import React, { MouseEventHandler, useEffect, useReducer } from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'
import RetroFit from './controllers/retroFit'
import PoleMounted from './controllers/poleMounted'
import NemaMounted from './controllers/nemaMounted'
import { TextNav } from 'atoms/links'

import nemaPDF from 'assets/pdf/NEMA-Mounted Street Light Controller.pdf'
import retrofitPDF from 'assets/pdf/Retrofit Street Light Controller.pdf'
import polePDF from 'assets/pdf/Pole Mount Street Light Controller.pdf'
import ilcofferingsPDF from 'assets/pdf/ILC_Offerings_brochure.pdf'

export default function Controller() {
  const location = useLocation()
  const data = location.state
  useEffect(() => {
    if (data && data.active) {
      dispatch(data.active - 1)
    }
  }, [data])

  const [view, dispatch] = useReducer(
    (state: number, payload: number) =>
      0 <= payload && payload <= 2 ? payload : state,
    0,
  )

  const exitAnim = (node: any) => {
    gsap.fromTo(
      node,
      { scale: 1, opacity: 1 },
      {
        opacity: 0,
        scale: 0.98,
        duration: 0.5,
      },
    )
  }

  const enter = (node: any) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        scale: 0.98,
      },
      {
        duration: 0.5,
        scale: 1,
        opacity: 1,
      },
    )
  }

  const Chip = ({
    text,
    onClick,
    index,
    subtext,
  }: {
    text: any
    subtext?: string
    index: number
    onClick: MouseEventHandler
  }) => {
    return (
      <button
        onClick={onClick}
        className={`${
          index === view
            ? ' tracking-wider bg-white border-blue text-blue'
            : 'bg-black border-gray text-white'
        } text-sm mx-auto xl:w-[250px] 2xl:w-[300px] font-normal outline-none 
          decoration-blue py-2 px-4 2xl:px-4 border-2 rounded-md focus:text-blue duration-300 transition-all `}
        aria-label="controller"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  }

  return (
    <>
      <div id="controllers" className="bg-black">
        <div>
          <div className="bg-black">
            <div
              className="pt-12 md:pt-10 xl:pt-28 bg-black"
              style={{ backgroundSize: '100% auto' }}
            >
              <h3
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="500"
                data-aos-once="false"
                className="text-gold-gradient text-transparent bg-clip-text text-center 
                font-medium px-5"
              >
                Individual Street Light <br className="md:block hidden" />
                Controllers
              </h3>
              <h5
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="500"
                data-aos-once="false"
                className="mt-2 md:mt-5 pb-12 md:pb-16 lg:pb-8 text-center font-normal px-3 tracking-wide 
                md:w-9/12 mx-auto text-white"
              >
                Presenting a suite of industry standard controllers
                <br className="md:block hidden" /> for the smart cities of
                tomorrow.
              </h5>
            </div>

            <div className="lg:hidden flex justify-center pb-10">
              <TextNav
                to={ilcofferingsPDF}  
                target="_blank"              
                title="Download the Brochure for more details"
                withIcon={true}
                color="white"
                className="border-1 border-blue py-2 px-5 bg-transparent"
              >
                Download The ILC Offerings Brochure
                <span className="sr-only">
                  Click here to download the ILC Offerings Brochure
                </span>
              </TextNav>
            </div>

            <div id="ISLC-section" className="bg-dark-products-blade">
              <div className="lg:block bg-black backdrop-blur-2xl bg-opacity-20 hidden py-3 sticky top-16 lg:top-20 z-50">
                <div className="flex gap-x-4 justify-between w-container mx-auto">
                  <div className="flex gap-x-4">
                    <Chip
                      text="NEMA-Mount <br /> Street Light Controller"
                      onClick={() => dispatch(0)}
                      index={0}
                    />
                    <Chip
                      text="Pole Mount <br /> Street Light Controller"
                      onClick={() => dispatch(1)}
                      index={1}
                    />
                    <Chip
                      text="Embedded Fixture Mounted <br /> Street Light Controller"
                      onClick={() => dispatch(2)}
                      index={2}
                    />
                  </div>
                  <div className="flex justify-center">
                    <TextNav
                      to={ilcofferingsPDF} 
                      target="_blank"                     
                      title="Download the Brochure for more details"
                      withIcon={true}
                      color="white"
                      className="border-1 border-blue py-2 px-5 bg-transparent"
                    >
                      Download The ILC Offerings Brochure
                      <span className="sr-only">
                        Click here to download The ILC Offerings Brochure
                      </span>
                    </TextNav>
                  </div>
                </div>
              </div>
              <div
                className="bg-dark-products-blad lg:block hidden"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="500"
                data-aos-once="false"
              >
                <SwitchTransition>
                  <Transition
                    appear={true}
                    timeout={500}
                    in={true}
                    key={view}
                    onExit={exitAnim}
                    onEnter={enter}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      {view === 0 && (
                        <div
                          data-aos="fade-up"
                          data-aos-delay="200"
                          data-aos-duration="500"
                          data-aos-once="false"
                          className="blade-bottom-padding"
                        >
                          <NemaMounted />

                          {/* statistics table */}

                          <div className=" px-2 py-3 hidden">
                            <h5 className=" text-white text-center mx-auto block font-medium">
                              What gives NEMA Mount Street Light <br />{' '}
                              Controller an edge?
                            </h5>
                            <StatisticsTable viewData={mountStreetLight} />
                          </div>
                        </div>
                      )}
                      {view === 2 && (
                        <div
                          data-aos="fade-up"
                          data-aos-delay="200"
                          data-aos-duration="500"
                          data-aos-once="false"
                          className="blade-bottom-padding"
                        >
                          <RetroFit />

                          {/* statistics table */}

                          <div className="hidden px-2 py-3 ">
                            <h5 className=" text-white text-center mx-auto block font-medium">
                              What gives Retrofit Street Light <br /> Controller
                              an edge?
                            </h5>
                            <StatisticsTable viewData={retroFitStreetLight} />
                          </div>
                        </div>
                      )}
                      {view === 1 && (
                        <div
                          data-aos="fade-up"
                          data-aos-delay="200"
                          data-aos-duration="500"
                          data-aos-once="false"
                          className="blade-bottom-padding"
                        >
                          <PoleMounted />

                          {/* statistics table */}

                          <div className="hidden px-2 py-3 ">
                            <h5 className=" text-white text-center mx-auto block font-medium">
                              What gives Pole Mount Street Light <br />{' '}
                              Controller an edge?
                            </h5>
                            <StatisticsTable viewData={poleMountStreetLight} />
                          </div>
                        </div>
                      )}
                    </div>
                  </Transition>
                </SwitchTransition>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View Statistics Table */}
      <div
        className="bg-dark-product pb-12
               md:pb-20 lg:hidden block bg-black"
      >
        <div className="">
          <NemaMounted />

          <div className="">
            <div className="px-3 max-w-screen-sm lg:w-full mx-auto hidden ">
              <StatisticsTable viewData={mountStreetLight} />
            </div>

            <div className="grid mt-6 place-content-start w-container">
              <TextNav
                to={nemaPDF}
                target="_blank"
                title="Download the Brochure for more details"
                withIcon={true}
                color="white"
                className="border-1 border-blue py-2 px-5 mr-auto  bg-transparent"
              >
                Download The Brochure
              </TextNav>
            </div>
          </div>
        </div>

        <div className="pt-12 md:pt-16">
          <PoleMounted />

          <div className="hidden">
            <div className=" px-3  max-w-screen-sm lg:w-full  mx-auto hidden">
              <StatisticsTable viewData={poleMountStreetLight} />
            </div>
          </div>

          <div className="w-container mt-6 grid place-content-start">
            <TextNav
              color="white"
              to={polePDF}
              target="_blank"
              title="Download the Brochure for more details"
              withIcon={true}
              className="border-1 border-blue py-2 px-5 mr-auto  bg-transparent"
            >
              Download The Brochure
            </TextNav>
          </div>
        </div>

        <div className="pt-12 md:pt-16">
          <RetroFit />

          <div className="hidden">
            <div className="px-3 max-w-screen-sm lg:w-full mx-auto hidden">
              <StatisticsTable viewData={retroFitStreetLight} />
            </div>
          </div>

          <div className="w-container mt-6 grid place-content-start">
            <TextNav
              to={retrofitPDF}
              title="Download the Brochure for more details"
              target="_blank"
              withIcon={true}
              color="white"
              className="border-1 border-blue py-2 px-5 mr-auto bg-transparent"
            >
              Download The Brochure
            </TextNav>
          </div>
        </div>
      </div>
    </>
  )
}
