import { useEffect } from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import { UIProvider } from './context/ui'
import routes from './router'

import AOS from 'aos'
import 'aos/dist/aos.css'

const RenderRoutes = () => {
  let routeTree = useRoutes(routes())
  return routeTree
}

function App() {
  useEffect(() => {
    AOS.refresh()
    AOS.init({
      duration: 700,
      delay: 400,
      once: true,
    })
    window.scrollTo(0, 0)
  }, [])

  return (
    <UIProvider>
      <BrowserRouter>
        <RenderRoutes />
      </BrowserRouter>
    </UIProvider>
  )
}

export default App
