import React, { useEffect } from 'react'
import CardFlex from './cardFlex'
import Features from './features'
import Title from './title'
import LoraWan from './lorawan'
import SoftwareServices from '../softwareServices'
import './style.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

// abstracted in a folder for brevity

export default function Products() {
  useEffect(() => {
    const mockupWrapper = document.querySelectorAll('.mockup-illustration')
    const settingsWrapper = document.querySelectorAll('.settings-icon')
    const appearWrapper = document.querySelectorAll('.appear-wrapper')
    // console.log(mockupWrapper, settingsWrapper)
    // remove the animation for mobiles. IOS phones do not well with gsap.
    mockupWrapper.forEach((item, id) => {
      if (window.innerWidth > 768) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: '10% 45%',
            end: 'bottom 80%',
            markers: false,
            scrub: 2,
          },
        })
        tl.fromTo(
          appearWrapper[id],
          {
            height: '110%',
          },
          {
            height: 0,
            duration: 2,
          },
        ).fromTo(
          settingsWrapper[id],
          { opacity: 0, scale: 0 },
          { opacity: 1, scale: 1 },
          '1',
        )
      }
    })
  }, [])

  return (
    <>
      <div className="md:animation-gradient bg-black">
        <Title />
        <Features />
        <CardFlex />
        <LoraWan />
        <SoftwareServices />
      </div>
    </>
  )
}
