import chevronWhite from 'assets/icons/arrow-down.svg'
import { Image } from 'atoms/image'
import { useEffect, useState } from 'react'

export default function ScrollDown() {
  const [height, setHeight] = useState<any | null>(null)

  const handleClick = () => {
    window.scrollTo({
      top: height,
      left: 0,
      behavior: 'smooth',
    })
  }
  useEffect(() => {
    let h = document.querySelector('.banner')?.clientHeight
    setHeight(h)
  }, [])
  return (
    <button
      onClick={handleClick}
      className="flex flex-nowrap items-center gap-3 md:gap-3 lg:gap-4"
      aria-label="scroll-down"
    >
      <div className="border-1 border-solid border-white p-2 rounded-full grid place-content-center">
        <Image
          src={chevronWhite}
          alt="Scroll Down"
          className="!h-5 md:h-auto animate-bounce cursor-pointer"
        />
      </div>
      <h6 className="text-white font-normal">Scroll Down</h6>
    </button>
  )
}
