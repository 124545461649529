import React, { Fragment, useReducer, MouseEventHandler, useMemo } from 'react'
import { Image } from 'atoms/image'
import ecosystemIcon from 'assets/icons/ecosystem.svg'
import ecosystem_video from 'assets/home/ecosystem_video_1.mp4'
import ecosystem_video_2 from 'assets/home/ecosystem_video_2.mp4'
import thumb1 from 'assets/home/video_thumb_1.webp'
import thumb2 from 'assets/home/video_thumb_2.webp'

import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'

export default function Ecosystem() {
  const [view, dispatch] = useReducer(
    (state: number, payload: number) =>
      0 <= payload && payload <= 2 ? payload : state,
    0,
  )

  const Chip = ({
    text,
    onClick,
    index,
    buttonStyle,
  }: {
    text: string
    index: number
    onClick: MouseEventHandler
    buttonStyle?: string
  }) => {
    const windowWidth = window.innerWidth

    return (
      <button
        onClick={(event) => {
          event.nativeEvent.stopImmediatePropagation()
          onClick(event)
        }}
        className={`  
         font-normal border-2 focus:outline-none active:outline-none
        ${
          index === view
            ? 'bg-blue text-white py-[9px] lg:border-blue lg:bg-white lg:text-blue'
            : 'bg-white text-blue py-2 border-blue lg:text-white lg:border-white lg:bg-darkGray'
        }
         border-solid transition-all
        duration-900 flex items-center justify-center capitalize 
        md:text-lg text-[16px]  xs:w-[280px] md:w-[335px] lg:w-[320px] 2xl:w-[350px] rounded-xl 
       px-4 md:px-4 lg:px-3 ${buttonStyle}`}
        aria-label="ecosystem"
      >
        {text}
        {/* <span
          className={`w-9 h-9 flex items-center ml-2 sm:ml-4 md:ml-0 md:w-10 md:h-10 
        ${
          windowWidth > 1024
            ? 'fill-[#3661FF]'
            : index === view
            ? 'fill-[#ffffff]'
            : 'fill-[#3661FF]'
        }`}
        >
          {index === view ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              width="inherit"
              height="inherit"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          ) : (
            <svg
              width="inherit"
              height="inherit"
              viewBox="0 0 35 35"
              fill="inherit"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.3823" cy="17.6934" r="16.3691" fill="inherit" />
              <path
                d="M14.5367 11.7232C13.8702 11.3232 13.0222 11.8033 13.0222 12.5806V22.8037C13.0222 23.581 13.8702 24.0611 14.5367 23.6612L23.056 18.5497C23.7033 18.1613 23.7033 17.2231 23.056 16.8347L14.5367 11.7232Z"
                fill={
                  windowWidth > 1024
                    ? '#ffffff'
                    : index === view
                    ? '#3661FF'
                    : '#ffffff'
                }
              />
            </svg>
          )}
        </span> */}
      </button>
    )
  }

  const exitAnim = (node: any) => {
    gsap.fromTo(
      node,
      { y: 0, opacity: 1, scale: 1 },
      {
        duration: 0.8,
        opacity: 0,
        scale: 0.98,
      },
    )
  }

  const enter = (node: any) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        scale: 0.98,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.8,
      },
    )
  }

  return (
    <Fragment key="home_products">
      <div className="w-container pt-10 sm:pt-14 lg:pt-20 ">
        <div className="flex flex-col flex-nowrap gap-2 items-center justify-center text-center">
          <div
            data-aos="fade-up"
            data-aos-duration="300"
            data-aos-once="false"
            className="flex flex-nowrap items-center gap-3"
          >
            <Image src={ecosystemIcon} alt="blogs" title="Blogs" />
            <h5 className="font-light">ViaLights Ecosystem</h5>
          </div>
          {/* <h2
          style={{ wordSpacing: '7px' }}
          data-aos="fade-up"
          data-aos-delay="200"
          className="pt-5  text-dark px-4 font-normal"
        >
          How does a smart street light <br className="md:block hidden" />{' '}
          ecosystem work?
        </h2> */}

          <div
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="300"
            data-aos-once="false"
          >
            <h2
              style={{
                wordSpacing:
                  window.innerWidth > 1366
                    ? '7px'
                    : window.innerWidth > 560
                    ? '3px'
                    : '0px',
              }}
              data-aos="fade-up"
              data-aos-delay="200"
              className="pt-4  text-dark px-4 font-normal"
            >
              A quick glance at the
              <br className="md:block hidden" /> Smart Street Light Ecosystem.
            </h2>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="300"
        data-aos-once="false"
        className="py-7 px-4 lg:hidden flex flex-col items-center sm:flex-row justify-center gap-4 md:gap-8 max-w-md 
        sm:max-w-3xl mx-auto"
      >
        <Chip
          text="Individual Street Light Control"
          onClick={() => dispatch(1)}
          index={1}
        />
        <Chip
          text="Group Street Light Control"
          onClick={() => dispatch(0)}
          index={0}
        />
      </div>
      <div>
        <div className="w-container lg:pt-10 pb-10 sm:pb-14 lg:pb-20">
          <div className="lg:h-[70vmin] relative rounded-2xl overflow-hidden">
            <div
              className="relative md:w-10/12 w-full max-w-4xl 2xl:max-w-6xl mx-auto"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="300"
              data-aos-once="false"
            >
              <div
                onClick={(event) => event.stopPropagation()}
                className="px-3 absolute left-8 2xl:left-8 z-10 py-5 hidden lg:grid grid-cols-1 lg:gap-4 
                  3xl:gap-5 max-w-4xl  mx-auto"
              >
                <Chip
                  text="Individual Street Light Control"
                  onClick={() => dispatch(1)}
                  index={1}
                />

                <Chip
                  text="Group Street Light Control"
                  onClick={() => dispatch(0)}
                  index={0}
                  buttonStyle="!xl:w-[319px]"
                />
              </div>

              <div className="w-full h-full lg:h-[70vmin] rounded-xl overflow-hidden bg-gradient-to-b from-black to-darkBlue ">
                <SwitchTransition>
                  <Transition
                    appear={true}
                    timeout={700}
                    in={true}
                    key={view}
                    onExit={exitAnim}
                    onEnter={enter}
                    unmountOnExit
                  >
                    <video
                      poster={view === 0 ? thumb1 : thumb2}
                      muted={true}
                      autoPlay={true}
                      loop
                      controls
                      className="relative lg:h-[70vmin] rounded-2xl object-center object-cover top-0 w-full  cursor-pointer"
                    >
                      <source
                        src={view === 0 ? ecosystem_video : ecosystem_video_2}
                        type="video/mp4"
                      />
                    </video>
                  </Transition>
                </SwitchTransition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
