import { TextNav } from 'atoms/links'
import { Image } from 'atoms/image'
import React, { useRef, useState } from 'react'

import lorawan_fv from 'assets/products/lorawan-front-view.webp'
import lorawan_lv from 'assets/products/lorawan-left-view.webp'
import lorawan_rv from 'assets/products/lorawan-right-view.webp'
import lorawan_sv from 'assets/products/lorawan-street-view.jpg'
import lorawan_fv_small from 'assets/products/lorawan-front-view_small.webp'
import lorawan_lv_small from 'assets/products/lorawan-left-view_small.webp'
import lorawan_rv_small from 'assets/products/lorawan-right-view_small.webp'
// import lorawan_sv_small from 'assets/products/lorawan-street-view_small.webp'
import lorawan_sv_small from 'assets/products/lorawan-street-view.webp'
import './style.css'

import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'

export default function Lorawan() {
  const [idx, setIdx] = useState(0)
  const imgRef = useRef(null)

  const exitAnim = (node: any) => {
    gsap.fromTo(
      node,
      { scale: 1, opacity: 1 },
      {
        opacity: 0,
        scale: 0.95,
        duration: 0.5,
      },
    )
  }

  const enter = (node: any) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        scale: 0.95,
      },
      {
        duration: 0.5,
        scale: 1,
        opacity: 1,
      },
    )
  }

  return (
    <section className="lorawan_wrapper mt-11 lg:-mt-[6px] z-0">
      <div
        className="w-container overflow-hidden lg:h-[700px] flex flex-nowrap gap-x-6 md:gap-x-10 
            lg:gap-x-16 xl:gap-x-20 items-center"
      >
        <div className="mockup-illustration  lg:block hidden relative basis-10  grow-0 shrink">
          <svg
            className=""
            width="78"
            height="800"
            viewBox="0 0 78 806"
            fill="none"
            stroke="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.06421 2.75977L2.06425 802.896"
              // stroke="url(#paint0_linear_2168_3356)"
              stroke="#fff"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M2.07837 1.50977L2.27257 236.47C4.62165 249.747 10.8397 279.626 24.8116 293.108C42.2764 309.96 58.8221 342.851 58.8221 370.015C58.8221 391.409 58.1071 473.885 58.8221 512.491C59.0918 527.057 49.0172 558.451 28.182 580.512C7.34669 602.573 2.99757 631.076 2.07837 641.392L2.33124 803.956"
              stroke="url(#paint1_linear_2168_3356)"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2168_3356"
                x1="0.0214948"
                y1="1.50977"
                x2="0.02152"
                y2="788.716"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" stopOpacity="0.12" />
                <stop offset="0.556748" stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2168_3356"
                x1="1.51632"
                y1="1.50977"
                x2="1.51632"
                y2="803.956"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D0B05D" stopOpacity="0" />
                <stop offset="0.5" stopColor="#D0B05D" />
                <stop offset="1" stopColor="#D0B05D" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
          <div className="h-11 w-11 settings-icon absolute top-[50%] -right-[2px]  rounded-full">
            <svg
              className="animate-spin-slow"
              viewBox="0 0 39 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.903965 21.8184V17.1295L6.06336 16.0743C6.06336 15.8391 6.17987 15.7226 6.17987 15.4873L2.31089 12.0866L4.65648 7.98236L9.58063 9.62449C9.69714 9.50798 9.81585 9.38928 9.93236 9.27277L8.29022 4.34861L12.3944 2.00302L15.7952 5.872C16.0304 5.872 16.1469 5.75549 16.3822 5.75549L17.3165 0.714844H22.0054L23.0606 5.87424C23.2958 5.87424 23.4123 5.99074 23.6475 5.99074L27.0483 2.12176L31.1525 4.46735L29.5104 9.39151C29.6269 9.50802 29.7456 9.62673 29.8621 9.74323L34.7862 8.1011L37.1318 12.2053L33.2629 15.6061C33.2629 15.8413 33.3794 15.9578 33.3794 16.193L38.42 17.1273V21.8163L33.2606 22.8714C33.2606 23.1067 33.1441 23.2232 33.1441 23.4584L37.0131 26.8592L34.6675 30.9634L29.7433 29.3212C29.6268 29.4377 29.5081 29.5565 29.3916 29.673L31.0338 34.5971L26.9295 36.9427L23.5288 33.0737C23.2936 33.0737 23.1771 33.1902 22.9418 33.1902L22.0075 38.2309H17.3186L16.2634 33.0715C16.0282 33.0715 15.9117 32.955 15.6765 32.955L12.2757 36.824L8.17148 34.4784L9.81362 29.5542C9.69711 29.4377 9.5784 29.319 9.46189 29.2025L4.53773 30.8446L2.19215 26.7404L6.06113 23.3397C6.06113 23.1044 5.94462 22.9879 5.94462 22.7527L0.903965 21.8184ZM12.6274 19.4728C12.6274 23.3418 15.7929 26.5073 19.6619 26.5073C23.5309 26.5073 26.6964 23.3418 26.6964 19.4728C26.6964 15.6038 23.5309 12.4383 19.6619 12.4383C15.7929 12.4383 12.6274 15.6038 12.6274 19.4728Z"
                fill="url(#paint0_linear_1834_4045)"
              />
              <circle
                cx="58.8275"
                cy="409.149"
                r="7.3927"
                fill="#010101"
              ></circle>
              <defs>
                <linearGradient
                  id="paint0_linear_1834_4045"
                  x1="19.662"
                  y1="0.714844"
                  x2="19.662"
                  y2="38.2309"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFE8AC" />
                  <stop offset="1" stopColor="#D0B05D" />
                  <stop offset="1" stopColor="#D0B05D" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="appear-wrapper appear-animation absolute bottom-0 border-none w-full animation-gradient"></div>
        </div>
        <div className="basis-96 grow shrink">
          <div className="flex lg:flex-row flex-col-reverse gap-x-10 lg:gap-y-10 ">
            <div className="flex flex-col  xl:basis-96 grow shrink  justify-center flex-nowrap xl:pt-10">
              <h3 className="max-w-2xl text-white font-normal text-opacity-90">
                LoRaWAN Gateway
              </h3>
              <h6 className="text-white text-opacity-60 font-light leading-normal max-w-xl mt-4 lg:pr-10 ">
                Pre-programmed with LoRaWAN Network Base Station, it helps
                seamlessly connect LoRa-enabled devices over 4G to the cloud.
                The device acts as a bridge between nodes and the LoRaWAN
                Network Server, seamlessly channelizing data transfer within an
                ecosystem.
              </h6>
              <div className="mr-auto grid mt-6  lg:mt-10">
                <TextNav
                  withBorder={true}
                  color="white"
                  to="/hardware-offerings#lorawan"
                  withIcon={true}
                  ariaLabel="Know More"
                  title="Know More"
                  rel="author"
                  target="_self"
                  className="py-2 md:py-4  lg:text-base bg-transparent"
                >
                  Know More
                </TextNav>
              </div>
            </div>
            <div className="basis-96 grow  shrink w-full max-w-2xl lg:mx-auto">
              <div
                className="bg-white max-w-[500px] xl:ml-auto product-card oveflow-hidden    
              rounded-xl"
              >
                <div
                  className="object-cover rounded-lg w-full 
                overflow-hidden"
                >
                  <SwitchTransition>
                    <Transition
                      appear={true}
                      timeout={500}
                      in={true}
                      key={idx}
                      onExit={exitAnim}
                      onEnter={enter}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div
                        className="h-80 sm:h-96 overflow-hidden w-full rounded-lg flex justify-center 
                      items-center "
                      >
                        <img
                          src={lorawan_sv}
                          className={` ${
                            idx === 0 ? '' : 'hidden '
                          }  h-full w-full rounded-lg object-cover select-none pointer-events-none `}
                          alt="right view loranwan gatway product"
                          ref={imgRef}
                        />
                        <img
                          src={lorawan_fv}
                          className={` ${
                            idx === 1 ? '' : 'hidden '
                          }  h-full w-full object-center object-contain p-4 md:p-7 select-none  pointer-events-none `}
                          alt="front view loranwan gatway product"
                          ref={imgRef}
                        />

                        <img
                          src={lorawan_lv}
                          className={` ${
                            idx === 2 ? '' : 'hidden '
                          }  h-full w-full object-center object-contain p-4 md:p-7 select-none  pointer-events-none `}
                          alt="left view loranwan gatway product"
                          ref={imgRef}
                        />

                        <img
                          src={lorawan_rv}
                          className={` ${
                            idx === 3 ? '' : 'hidden '
                          }  h-full w-full object-center object-contain p-4 md:p-7 select-none  pointer-events-none `}
                          alt="right view loranwan gatway product"
                          ref={imgRef}
                        />
                      </div>
                    </Transition>
                  </SwitchTransition>
                </div>
              </div>
              <div>
                <div className="mt-8 h-20 flex flex-row md:justify-between max-w-[500px] gap-4 xl:ml-auto">
                  <button
                    onClick={() => setIdx(0)}
                    className={` ${
                      idx === 0
                        ? 'border-[3px] border-solid border-gray border-opacity-60'
                        : ''
                    } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 bg-gray bg-opacity-20 active:outline-none focus:outline-none rounded-lg flex items-center 
                    justify-center overflow-hidden`}
                    aria-label="thumbnail"
                  >
                    <Image
                      src={lorawan_sv_small}
                      alt="lorawan"
                      className="h-full !w-full pointer-events-none select-none"
                    />
                  </button>
                  <button
                    onClick={() => setIdx(1)}
                    className={` ${
                      idx === 1
                        ? 'border-[3px] border-solid border-gray border-opacity-60'
                        : ''
                    }  h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 flex active:outline-none focus:outline-none items-center rounded-lg justify-center bg-white  p-1 md:p-3  overflow-hidden`}
                    aria-label="thumbnail"
                  >
                    <Image
                      src={lorawan_fv_small}
                      alt="lorawan"
                      className="h-full w-full  pointer-events-none select-none"
                    />
                  </button>

                  <button
                    onClick={() => setIdx(2)}
                    className={` ${
                      idx === 2
                        ? 'border-[3px] border-solid border-gray border-opacity-60'
                        : ''
                    }  h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 flex active:outline-none focus:outline-none items-center rounded-lg justify-center bg-white p-1 md:p-3 overflow-hidden`}
                    aria-label="thumbnail"
                  >
                    <Image
                      src={lorawan_lv_small}
                      alt="lorawan"
                      className="w-full h-full pointer-events-none select-none"
                    />
                  </button>

                  <button
                    onClick={() => setIdx(3)}
                    className={` ${
                      idx === 3
                        ? 'border-[3px] border-solid border-gray border-opacity-60'
                        : ''
                    }  h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-24 xl:w-28 flex focus:outline-none active:outline-none rounded-lg items-center justify-center bg-white  p-1 md:p-3 overflow-hidden`}
                    aria-label="thumbnail"
                  >
                    <Image
                      src={lorawan_rv_small}
                      alt="lorawan"
                      className="h-full w-full pointer-events-none select-none"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
