import { TextNav } from 'atoms/links'
import productOne from 'assets/home/card_flex/NEMA.webp'
import productThree from 'assets/home/card_flex/pole_mount.webp'
import productTwo from 'assets/home/card_flex/retrofit_street.webp'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
interface Blog {
  title: string
  desc: string
  target: string
  coverImg: any
  activeIdx: number
  index?: number
}
const flexData = [
  {
    title: 'NEMA Mount Street Light Controller',
    coverImg: productOne,
    activeIdx: 1, // do not pass zero value since boolean coercion value is false.
    target: '/hardware-offerings#controllers',
    desc: 'The controller integrates with the LED light through NEMA connectors, providing added robustness towards external environment, making it ideal for outdoor deployments.',
  },
  {
    title: 'Pole Mount Street Light Controller',
    coverImg: productThree,
    activeIdx: 2,
    target: '/hardware-offerings#controllers',
    desc: 'To keep deployment costs at a minimum, ViaLights’ engineers have invented several mounting solutions to ensure smooth lamp upgrades and fast lighting controller installation.',
  },
  {
    title: 'Embedded Fixture Mounted Street Light Controller',
    coverImg: productTwo,
    target: '/hardware-offerings#controllers',
    activeIdx: 3,
    desc: 'Designed to minimize occupied space, the embedded streetlight controller is the best way to keep the lamp’s architectural value intact. Mounted inside the fixture, the controller can be wire-connected into most luminaire designs and can be embedded straight from the lamp production line, allowing any producer to offer ready smart street lighting fixtures. ',
  },
]

export default function CardFlex() {
  const Card = ({ title, desc, target, coverImg, index, activeIdx }: Blog) => {
    return (
      <article className="cards-islc flex  flex-col flex-nowrap rounded-lg overflow-hidden self-stretch h-full z-50">
        {/* Individual Street Light Controllers  */}
        <div className=" basis-0 lg:basis-96 2xl:basis-80 px-6 flex flex-col gap-1 pt-10 pb-8  bg-darkGray grow">
          <h5 className="font-medium text-lg sm:text-xl lg:text-lg xl:text-2xl text-white pr-5">
            {title}
          </h5>
          <span className="font-light text-sm tracking-normal lg:tracking-wider xl:text-base text-white text-opacity-75 pt-2 flex-1 font-thin ">
            {desc}
          </span>
          <div className="mt-4 grid place-content-start overflow-hidden">
            <TextNav
              state={{ active: activeIdx }}
              color="white"
              to={target}
              ariaLabel="Know More"
              title="Know More"
              rel="author"
              target="_self"
            >
              Know More
            </TextNav>
          </div>
        </div>
        <div className="py-5 grid place-content-center h-64 md:h-72 product-card">
          <img
            src={coverImg}
            alt={title}
            className={` w-full object-contain object-center h-64 md:h-72   py-3 ${
              index === 1 ? ' p-2 md:p-5' : ''
            }`}
          />
        </div>
      </article>
    )
  }

  return (
    <>
      <div className="md:grid pl-[21px] hidden gap-6 grid-cols-three w-container mt-6 lg:mt-20">
        {flexData.map((elem: Blog, index: number) => {
          const { title, desc, target, coverImg, activeIdx } = elem
          return (
            <Card
              index={index}
              activeIdx={activeIdx}
              coverImg={coverImg}
              key={index}
              title={title}
              desc={desc}
              target={target}
            />
          )
        })}
      </div>
      <Swiper
        autoHeight={false}
        slidesPerView={1}
        autoplay
        pagination
        spaceBetween={10}
        modules={[Pagination, Autoplay]}
        className="md:hidden block pb-10 gap-6 grid-cols-three w-container mt-8 lg:-mt-10"
      >
        {flexData.map((elem: Blog, index: number) => {
          const { title, desc, target, coverImg, activeIdx } = elem
          return (
            <SwiperSlide key={index}>
              <Card
                activeIdx={activeIdx}
                coverImg={coverImg}
                title={title}
                desc={desc}
                target={target}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}
