import React, {
  useState,
  Fragment,
  MouseEventHandler,
  useReducer,
  useEffect,
} from 'react'
import { Image } from 'atoms/image'
import blogsIcon from 'assets/icons/blogs.svg'
import { ResourceCard } from 'molecules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'
import blogs from 'static/blogs'
import caseStudies from 'static/caseStudies'
const OptionsMap = {
  blogs: blogs,
  // 'case-studies': caseStudies,
}
type Views = keyof typeof OptionsMap

// const Chip = ({
//   text,
//   view,
//   onClick,
//   option,
// }: {
//   text: string
//   view: string
//   onClick: MouseEventHandler<HTMLButtonElement>
//   option: string
// }) => {
//   return (
//     <button
//       onClick={onClick}
//       role="button"
//       type="button"
//       className={`${
//         option === view
//           ? ' text-gold border-gold bg-gray'
//           : ' border-transparent text-white bg-transparent '
//       }
//            font-normal border-2 border-solid bg-opacity-50 capitalize md:text-lg text-base py-1 px-6 rounded-lg
//         `}
//     >
//       {text}
//     </button>
//   )
// }
const Chip = ({
  text,
  view,
  onClick,
  option,
}: {
  text: string
  view: string
  onClick: MouseEventHandler<HTMLButtonElement>
  option: string
}) => {
  return (
    <button
      className={`${
        option === view
          ? ' text-gold border-gold bg-gray'
          : ' border-transparent text-white bg-transparent '
      }
             font-normal border-2 border-solid bg-opacity-50 capitalize md:text-lg text-base py-1 px-6 rounded-lg
          `}
      aria-label="chip"
    >
      {text}
    </button>
  )
}

export default function Blogs() {
  const [swiper, setSwiper] = useState(0)

  const [view, dispatch] = useReducer((state: Views, update: Views): Views => {
    if (Object.keys(OptionsMap).includes(update)) return update
    else return state
  }, 'blogs')

  // useEffect(() => {
  // const container = document.querySelector('.w-container') as HTMLElement
  // const sliderContainer = document.querySelector(
  //   '.sliderContainer',
  // ) as HTMLElement
  // const computedMargin =
  //   parseFloat(window.getComputedStyle(container).marginLeft) + 12
  // console.log(
  //   parseFloat(window.getComputedStyle(container).marginLeft),
  //   'com',
  // )
  // sliderContainer.style.paddingLeft = computedMargin + 'px'
  // }, [])

  return (
    <Fragment key="blogs">
      <div
        className={`bg-[url('assets/backgrounds/home_blog_bg.webp')] bg-no-repeat bg-cover bg-center 
        blade-top-padding-sm blade-bottom-padding-sm
       `}
      >
        <div className="w-container">
          <div
            className="flex flex-nowrap items-start gap-3"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
          >
            <Image src={blogsIcon} alt="blogs" title="Blogs" />
            <h5 className="text-white font-normal">Blogs & Articles</h5>
          </div>
          <h3
            className="text-gold-gradient font-light text-transparent bg-clip-text mt-4  md:mt-6"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-once="false"
          >
            Get acquainted with the
            <br className="md:block hidden" /> latest updates
          </h3>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
            data-aos-once="false"
          >
            <div className="flex items-center justify-start gap-2 py-4 md:py-6  xl:py-10 ">
              {/* {(Object.keys(OptionsMap) as Array<Views>).map((elem, index) => {
                return (
                  <Chip
                    key={index}
                    text={elem.split('-').join(' ')}
                    option={elem}
                    onClick={() => dispatch(elem)}
                    view={view}
                  />
                )
              })} */}
              <span
                className="text-gold font-normal border-2 border-solid bg-opacity-50 capitalize md:text-lg 
               text-base py-1 px-6 rounded-lg cursor-default border-gold bg-gray"
              >
                Blogs
              </span>
            </div>
          </div>
        </div>
        <div
          className={`sliderContaine 2xl:pl-[10%] md:pl-[5%]  ml-3`}
          data-aos="fade-up"
          data-aos-delay="250"
          data-aos-duration="500"
          data-aos-once="false"
        >
          <Swiper
            slidesPerView={1.2}
            slideToClickedSlide
            centeredSlides={false}
            loop={false}
            spaceBetween={30}
            modules={[Autoplay, Navigation]}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2.2,
              },
              1024: {
                slidesPerView: 2.2,
              },
              1536: {
                slidesPerView: 2.5,
              },
            }}
            navigation={{
              nextEl: '.swiper-btn-next',
              prevEl: '.swiper-btn-prev',
            }}
            autoHeight={false}
            className="sliderContaine cursor-grab"
          >
            {OptionsMap[view].map((elem, index) => {
              const { title, desc, target, bg } = elem
              return (
                <SwiperSlide className="min-h-full self-stretch" key={index}>
                  <ResourceCard
                    title={title}
                    key={index}
                    desc={desc}
                    target={target}
                    bg={bg}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div className="flex gap-2 md:gap-4 pt-10 md:pt-14">
            <button
              className={`swiper-btn-prev swiper-button-prev`}
              aria-label="previous"
            >
              <svg
                className="w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
              </svg>
            </button>
            <button
              className={`swiper-btn-next swiper-button-next`}
              aria-label="next"
            >
              <svg
                className="w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
