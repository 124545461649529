import { Link } from 'react-router-dom'
import { PropsWithChildren, ReactNode } from 'react'
import chevronRightBlue from 'assets/icons/chevron-right-blue.svg'
import chevronRightWhite from 'assets/icons/chevron-right-white.svg'
import { Image } from 'atoms/image'

interface AnchorProps {
  to: string
  ariaLabel?: string
  title?: string
  children: ReactNode
  callback?: any
  className?: string
}

interface TextAnchorProps extends AnchorProps {
  color?: 'white' | 'dark' | 'blue'
  withIcon?: boolean
  withBorder?: boolean
  referrerPolicy?:
    | 'no-referrer'
    | 'no-referrer'
    | 'no-referrer-when-downgrade'
    | 'unsafe-url'
  rel?: 'author' | 'next' | 'alternate' | 'nofollow' | 'noopener'
  target?: '_blank' | '_top' | '_self' | '_parent'
}

interface MasterNavProps {
  withIcon: boolean
  showRightChevron: boolean
}

const NavOption = ({
  to,
  ariaLabel,
  children,
  title,
  className,
  callback,
}: AnchorProps) => {
  return (
    <Link
      onClick={callback}
      to={to}
      aria-label={ariaLabel}
      title={title}
      className={className + ' font-light'}
    >
      {children}
    </Link>
  )
}

const TextNav = ({
  to,
  state,
  ariaLabel,
  children,
  title,
  className,
  referrerPolicy = 'no-referrer-when-downgrade',
  rel = 'author',
  target = '_self',
  color = 'blue',
  withIcon = true,
  withBorder = false,
}: TextAnchorProps & { state?: any }) => {
  return (
    <Link
      to={to}
      rel={rel}
      state={state}
      referrerPolicy={referrerPolicy}
      aria-label={ariaLabel}
      target={target}
      className={`${className} flex whitespace-nowrap flex-nowrap group font-normal gap-2 sm:gap-3 text-${color} items-center rounded-lg transition-all duration-300 ease-in-out  md:text-lg text-base ${
        withBorder
          ? ' border-1 border-opacity-80  px-6 hover:bg-blue mr-auto py-3  border-solid border-white  cursor-pointer'
          : 'hover:text-blue'
      }`}
    >
      {children}
      {withIcon ? (
        withBorder ? (
          <div className="h-6 w-6 relative">
            <Image
              className="h-6 w-6 absolute left-0 right-0 bottom-0 top-0  group-hover:opacity-100 opacity-0 transition-all duration-300 ease-in-out"
              src={chevronRightWhite}
              alt="chevron down"
            />
            <Image
              className="h-6 w-6    absolute left-0 right-0 bottom-0 top-0  group-hover:opacity-0 opacity-100 transition-all duration-300 ease-in-out "
              src={chevronRightBlue}
              alt="chevron down"
            />
          </div>
        ) : (
          <Image
            className="h-6 w-6 "
            src={chevronRightBlue}
            alt="chevron down"
          />
        )
      ) : (
        ''
      )}
    </Link>
  )
}

const MasterNav = ({
  to,
  ariaLabel,
  children,
  title,
  referrerPolicy = 'no-referrer-when-downgrade',
  rel = 'author',
  target = '_blank',
  withIcon = false,
  showRightChevron,
  className,
}: TextAnchorProps & MasterNavProps) => {
  return (
    <a
      href={to}
      rel={rel}
      referrerPolicy={referrerPolicy}
      aria-label={ariaLabel}
      title={title}
      target={target}
      className={`${className} flex flex-nowrap font-sequel-regular gap-3 hover:bg-blue hover:text-white text-blue  group items-center rounded-md bg-white font-medium text-base md:text-lg duration-300 transition-all ease-in-out`}
    >
      {children}
      {withIcon && !showRightChevron && (
        <div
          aria-hidden
          className=" w-6 h-6 rounded-full grid place-content-center bg-blue group-hover:bg-white  fill-white  group-hover:fill-blue  duration-300 transition-all ease-in-out"
        >
          <svg
            className="w-full h-full p-1"
            viewBox="0 0 12 13"
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.856286 5.44939C0.383373 5.44939 0 5.83276 0 6.30567C0 6.77859 0.383373 7.16196 0.856286 7.16196L0.856286 5.44939ZM11.5745 6.91116C11.9089 6.57676 11.9089 6.03459 11.5745 5.70019L6.12504 0.250797C5.79064 -0.0836027 5.24847 -0.0836027 4.91407 0.250797C4.57967 0.585197 4.57967 1.1274 4.91407 1.4618L9.75796 6.30567L4.91407 11.1496C4.57967 11.484 4.57967 12.0261 4.91407 12.3605C5.24847 12.6949 5.79064 12.6949 6.12504 12.3605L11.5745 6.91116ZM0.856286 7.16196L10.969 7.16196V5.44939L0.856286 5.44939L0.856286 7.16196Z"
              fill="inherit"
            />
          </svg>
        </div>
      )}
      {withIcon && showRightChevron && (
        <svg
          width={34}
          height={35}
          viewBox="0 0 34 35"
          fill="none"
          className="d group-hover:fill-white group-hover:stroke-blue duration-300 transition-all ease-in-out"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16.9337" cy="17.6719" r="16.3691" fill="inherit" />
          <path
            d="M11.0632 16.707C10.5109 16.707 10.0632 17.1547 10.0632 17.707C10.0632 18.2593 10.5109 18.707 11.0632 18.707V16.707ZM23.5802 18.4141C23.9707 18.0236 23.9707 17.3904 23.5802 16.9999L17.2162 10.636C16.8257 10.2454 16.1925 10.2454 15.802 10.636C15.4115 11.0265 15.4115 11.6597 15.802 12.0502L21.4589 17.707L15.802 23.3639C15.4115 23.7544 15.4115 24.3876 15.802 24.7781C16.1925 25.1686 16.8257 25.1686 17.2162 24.7781L23.5802 18.4141ZM11.0632 18.707H22.8731V16.707H11.0632V18.707Z"
            fill="inherit"
          />
        </svg>
      )}
    </a>
  )
}

type ExternalLinkProps = PropsWithChildren<{
  target: '_blank' | '_self'
  href: string
  desc: string
}>
const ExternalLink = ({ target, href, desc, children }: ExternalLinkProps) => {
  return (
    <a href={href} target={target} rel="noreferrer noopener">
      {children}
      <span className="sr-only"> {desc}</span>
    </a>
  )
}
export { NavOption, TextNav, MasterNav, ExternalLink }
