import iconA from 'assets/home/platforms/platform_iconA.svg'
import iconB from 'assets/home/platforms/platform_iconB.svg'
import iconC from 'assets/home/platforms/platform_iconC.svg'
import productGrid from 'assets/home/products_grid.webp'
import { Image } from 'atoms/image'
import { useEffect } from 'react'
export default function Platform() {
  const Card = ({
    title,
    icon,
    index,
  }: {
    index: number
    title: string
    icon: string
  }) => {
    return (
      <article
        data-aos="fade"
        data-aos-delay={100 * index}
        data-aos-duration="500"
        data-aos-once="false"
        className="bg-white bg-blue-light-gradient cursor-default max-w-[500px] lg:mx-0 lg:basis-96 w-full 
        lg:w-auto lg:grow-0 shrink px-5 md:px-6 lg:px-10 py-10 rounded-xl overflow-hidden flex flex-col 
       items-start gap-2 "
      >
        <img
          src={icon}
          alt="Card Icon"
          aria-hidden={true}
          loading="lazy"
          className={`${index === 3 ? '' : 'pt-2 md:pt-6'} h-12 lg:h-20`}
        />
        <span className=" text-lg flex-1 sm:text-xl  leading-normal md:leading-snug lg:leading-relaxed mt-3 md:mt-8 font-normal">
          {title}{' '}
        </span>
      </article>
    )
  }
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-duration="500"
        data-aos-once="false"
        className="px-3 lg:hidden lg:mt-16 mb-12 "
      >
        <Image
          src={productGrid}
          alt="product grid "
          className="h-auto px-3 object-contain object-center"
        />
      </div>
      <div className="gradient-dark">
        <div
          className="bg-[url('assets/backgrounds/city-landscape.svg')] bg-no-repeat bg-[length:250%] md:bg-[length:100%] 
         md:bg-[center_bottom_5%] bg-[center_bottom_0] blade-bottom-padding relative"
        >
          <div className="xl:pt-96 lg:pt-64">
            <div className="w-[90%] 2xl:w-full mx-auto 2xl:pt-20 lg:pt-8 pt-12">
              <div className="max-w-[1240px] mx-auto">
                <h3
                  className="text-gold-gradient text-transparent bg-clip-text font-light"
                  data-aos="fade"
                  data-aos-delay="300"
                  data-aos-duration="500"
                  data-aos-once="false"
                >
                  The platform assists city <br className="md:block hidden" />{' '}
                  managers on multiple fronts
                </h3>
              </div>

              <div
                className="flex justify-start lg:justify-center
              lg:flex-row flex-col gap-10 md:mt-8 mt-6 lg:mt-12"
              >
                <Card
                  index={2}
                  icon={iconA}
                  title="Ensures smart monitoring and control of the street light infrastructure."
                />
                <Card
                  index={3}
                  icon={iconB}
                  title="Ensures real-time actionable analytics on power failures, lamp malfunctions, and voltage failures.  "
                />
                <Card
                  index={4}
                  icon={iconC}
                  title="Alerts for power thefts, street light malfunctions and short circuits.
"
                />
              </div>
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="500"
            data-aos-once="false"
            className="z-10 absolute lg:-top-72 bottom-auto left-0 right-0 hidden lg:block  lg:h-[30rem] xl:h-[36rem] 2xl:h-[40rem]"
          >
            <Image
              src={productGrid}
              alt="product grid "
              className="h-full w-full block absolute left-0 right-0 bottom-0 top-0 mx-auto  object-contain object-center max-w-screen-xl"
            />
          </div>
        </div>
      </div>
    </>
  )
}
