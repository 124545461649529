import React, { useReducer, useRef, useState, useEffect } from 'react'
import logoLight from 'assets/globals/logo_dark.png'
import logoDark from 'assets/globals/logo_light.png'
import { NavOption, TextNav } from 'atoms/links'
import { Image } from 'atoms/image'
import MobileNavModal from 'molecules/mobileNavModal'

import { Transition } from 'react-transition-group'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
gsap.registerPlugin(ScrollTrigger)

export default function Header() {
  const [mobileNav, toggleMobileNav] = useReducer((s) => !s, false)
  const [isNavFixed, setisNavFixed] = useState(false)
  let header = useRef<HTMLHeadElement>(null)
  const modalRef = useRef(null)

  const handleScroll = (e: Event) => {
    var scrollPos = window.scrollY
    if (scrollPos > 80) {
      setisNavFixed(true)
    } else {
      setisNavFixed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  const modalAnimation = (node: any) => {
    const tl = gsap.timeline()
    tl.fromTo(
      node,
      { opacity: 0, zIndex: -1 },
      { opacity: 1, duration: 0.3, zIndex: 60 },
    )
    tl.fromTo(
      modalRef.current,
      {
        opacity: 0,
        xPercent: 100,
      },
      {
        xPercent: 0,
        opacity: 1,
        duration: 0.3,
      },
    )
    return tl
  }

  return (
    <>
      <header
        ref={header}
        className={` fixed left-0 right-0 z-[999] top-0  transition-all duration-300 ease-in-out ${
          isNavFixed ? 'bg-[#ffffff] shadow-md' : 'bg-transparent'
        }`}
      >
        <nav className="flex flex-nowrap items-center justify-between w-container  h-16 lg:h-20 z-50">
          <NavOption to="/" ariaLabel="vialights website">
            {!isNavFixed ? (
              <Image
                src={logoDark}
                alt="vialights logo"
                loading="eager"
                className=" h-8 md:h-10 cursor-pointer  lg:h-10 object-cover"
              />
            ) : (
              <Image
                src={logoLight}
                alt="vialights logo"
                loading="eager"
                className=" h-8 md:h-10  cursor-pointer lg:h-10 object-cover"
                title="vialights logo"
              />
            )}
          </NavOption>
          <div className="flex-nowrap items-center justify-end gap-10 lg:flex hidden ">
            <div className="dropdown">
              <NavLink
                to="/hardware-offerings"
                className={(navData) =>
                  (navData.isActive
                    ? 'text-gold'
                    : isNavFixed
                    ? 'text-dark'
                    : 'text-white') +
                  ' text-lg font-normal tracking-wider focus:outline-none outline-none underline-offset-4  focus-visible:underline hover:text-gold'
                }
              >
                Hardware Offerings
              </NavLink>
              <div className="dropdown-content bg-transparent !shadow-none">
                <div className="mt-4 rounded-lg bg-white  h-full p-6 grid gap-5 shadow">
                  <Link
                    className="text-black tracking-wider font-normal hover:text-blue"
                    to="/hardware-offerings#ccms"
                    children="Centralized Control & Monitoring System"
                    title="Monitoring System Monitoring System"
                  />
                  <Link
                    className="text-black tracking-wider font-normal hover:text-blue"
                    to="/hardware-offerings#controllers"
                    children="Individual Street Light Controllers"
                    title="Individual Street Light Controllers"
                  />
                  <Link
                    className="text-black tracking-wider font-normal hover:text-blue"
                    to="/hardware-offerings#lorawan"
                    children="LoRaWAN Gateway"
                    title="LoRaWAN Gateway"
                  />
                </div>
              </div>
            </div>
            <div className="dropdown">
              <NavLink
                to="/software-services"
                className={(navData) =>
                  (navData.isActive
                    ? 'text-gold'
                    : isNavFixed
                    ? 'text-dark'
                    : 'text-white') +
                  ' text-lg  font-normal tracking-wider focus:outline-none outline-none underline-offset-4  focus-visible:underline hover:text-gold'
                }
              >
                Software Services
              </NavLink>
              <div className="dropdown-content bg-transparent !shadow-none">
                <div className="mt-4 rounded-lg bg-white h-full p-6 grid gap-5 shadow">
                  <Link
                    className="text-black tracking-wider font-normal hover:text-blue"
                    to="/software-services#software"
                    children="Lighting Management Software"
                    title="Lighting Management Software"
                  />
                  <Link
                    className="text-black tracking-wider font-normal hover:text-blue"
                    to="/software-services#application"
                    children="LMS Mobile Application"
                    title="LMS Mobile Application"
                  />
                </div>
              </div>
            </div>

            <NavLink
              to="/contact"
              className={(navData) =>
                (navData.isActive
                  ? 'text-gold'
                  : isNavFixed
                  ? 'text-dark'
                  : 'text-white') +
                ' text-lg  font-normal tracking-wider focus:outline-none outline-none underline-offset-4  focus-visible:underline hover:text-gold'
              }
            >
              Contact Us
            </NavLink>
          </div>
          <div className="lg:hidden block">
            <button
              onClick={toggleMobileNav}
              className="fill-dark hover:fill-blue p-2 rounded-full "
              aria-label="Mobile Navigation Menu"
            >
              <svg
                width="24"
                fill={isNavFixed ? 'black' : 'white'}
                height="24"
                viewBox="0 0 16.933 16.933"
              >
                <path
                  fill={isNavFixed ? 'black' : 'white'}
                  d="M12.271 1.323H1.984c-1.1-.042-1.1 1.63 0 1.588H12.23c1.08.042 1.122-1.588.042-1.588zM1.984 7.673c-1.058 0-1.058 1.587 0 1.587h5.8c1.08 0 1.08-1.587 0-1.587zm0 6.35c-1.058 0-1.058 1.587 0 1.587h12.997c1.058 0 1.058-1.587 0-1.587z"
                />
              </svg>
            </button>
          </div>
        </nav>
      </header>
      <Transition
        appear={true}
        timeout={2500}
        in={mobileNav}
        onEnter={(node: any) => modalAnimation(node).play()}
        onExit={(node: any) => modalAnimation(node).reverse(0)}
        mountOnEnter
        unmountOnExit
      >
        <div className="modal-bg ">
          <div
            className="modal-wrapper fixed top-0 left-0 bottom-0 right-0 bg-white z-[9999]"
            ref={modalRef}
          >
            <MobileNavModal toggle={toggleMobileNav} />
          </div>
        </div>
      </Transition>
    </>
  )
}
