import React, { useRef, useState } from 'react'
import lorawan_fv from 'assets/products/lorawan-front-view.webp'
import lorawan_lv from 'assets/products/lorawan-left-view.webp'
import lorawan_rv from 'assets/products/lorawan-right-view.webp'
import lorawan_sv from 'assets/products/lorawan-street-view.webp'
import lorawan_fv_small from 'assets/products/lorawan-front-view_small02.png'
import lorawan_lv_small from 'assets/products/lorawan-left-view_small02.png'
import lorawan_rv_small from 'assets/products/lorawan-right-view_small02.png'
// import lorawan_sv_small from 'assets/products/lorawan-street-view_small.webp'
import lorawan_sv_small from 'assets/products/lorawan-street-view.webp'
import '../home/products/style.css'

import one from 'assets/specs-icons/03/Website icons SVG-13.svg'
import two from 'assets/specs-icons/03/Website icons SVG-14.svg'
import three from 'assets/specs-icons/03/Website icons SVG-15.svg'
import four from 'assets/specs-icons/03/Website icons SVG-16.svg'

import { Image } from 'atoms/image'
import { MasterNav } from 'atoms/links'
import globe from 'assets/icons/globe.svg'
import dashed_line from 'assets/icons/dashed-line.svg'
import StatisticsTable from 'organisms/statisticsTable'
import gatewayData from 'static/gatewayTable.json'
import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'
import gatewayBrochure from 'assets/pdf/lorawan_brochure.pdf'
import ListImage from 'atoms/listImage'

export default function Gateway() {
  const [idx, setIdx] = useState(0)
  const imgRef = useRef(null)

  const exitAnim = (node: any) => {
    gsap.fromTo(
      node,
      { scale: 1, opacity: 1 },
      {
        opacity: 0,
        scale: 0.95,
        duration: 0.5,
      },
    )
  }

  const enter = (node: any) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        scale: 0.95,
      },
      {
        duration: 0.5,
        scale: 1,
        opacity: 1,
      },
    )
  }

  return (
    <div
      id="lorawan"
      className="blade-top-padding-sm lg:blade-top-padding blade-bottom-padding-sm
      lg:blade-bottom-padding"
    >
      <div className="bg-no-repeat bg-cover bg-center">
        {/* Mobile View */}
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="500"
          data-aos-once="false"
          className="basis-10 md:hidden pt-1 2xl:pt-3 grow-0 shrink w-11/12 mx-auto pb-5 
          flex flex-col justify-center"
        >
          <Image
            src={globe}
            alt="Globe Icon"
            className="select-none h-8 md:h-auto mb-3 md:mb-0 mr-auto"
          />

          <h3 className="text-blue-gradient text-transparent block md:hidden bg-clip-text font-semibold">
            LoRaWAN Gateway
          </h3>
        </div>
        <div
          className="flex-col-reverse lg:flex-row justify-around gap-x-10   
        relative flex w-container"
        >
          <div className="basis-96 md:pt-6 lg:pt-0 grow shrink flex flex-col md:flex-row flex-nowrap">
            <div
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="false"
              className="basis-10 2xl:pt-3 grow-0 shrink  md:flex hidden flex-col 
              "
            >
              <Image
                src={globe}
                alt="Globe Icon"
                className="select-none h-8 md:h-auto mb-3 md:mb-0  mr-auto"
              />
              <Image
                src={dashed_line}
                alt="Dashed Line"
                className="mx-auto select-none md:block hidden"
              />
            </div>
            <div
              // data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="false"
              className="flex-1 md:pl-3 lg:pl-7 w-full shrink 2xl:max-w-2xl max-w-xl  flex flex-col gap-4 flex-nowrap "
            >
              <h3 className="text-blue-gradient text-transparent md:block hidden bg-clip-text font-semibold">
                LoRaWAN Gateway
              </h3>
              <span className="text-black text-opacity-60 font-normal  text-base  md:text-lg   max-w-md xl:max-w-xl">
                ViaLights’ Gateway is a state-of-the-art network interface
                device that bridges the outdoor lighting controller network to
                the Smart City platform. The Gateway has an in-built radio
                module for wireless network configuration, commissioning, and
                maintenance. It acts as a data concentrator to reliably
                communicate with a large number of devices spread across large
                distances. <br />
                <br /> Several internet connectivity options offer robustness
                and flexibility. The Gateway for smart streetlights encloses
                advanced industrial components for optimized performance
                worldwide.
              </span>
              <div className="flex-1 shrink  max-w-2xl flex flex-col gap-4 flex-nowrap">
                <div className="mt-5  flex-1 flex md:flex-row flex-col items-start gap-1 gap-y-7 md:gap-x-4 lg:gap-x-10">
                  <div className="md:basis-96 grow shrink">
                    <span className="2xl:text-xl text-lg tracking-tight font-medium">
                      Redefining its category space
                    </span>
                    <div className="flex flex-col gap-y-6 lg:gap-y-12">
                      <div className="flex mt-6 flex-col gap-y-6 lg:flex-row justify-between items-start gap-x-4">
                        <div className="w-[80%] lg:w-[50%]">
                          <ListImage
                            image={one}
                            text="Supports offline uplink LoRa storage of up to 5,000 packets."
                          />
                        </div>
                        <div className="w-[80%] lg:w-[50%]">
                          <ListImage
                            image={two}
                            text="Equipped with open-source embedded LINUX System."
                          />
                        </div>
                      </div>
                      <div className="flex justify-between flex-col lg:flex-row items-start gap-y-6 gap-x-4">
                        <div className="w-[80%] lg:w-[50%]">
                          <ListImage
                            image={three}
                            text="Built-in GPS for location and timing."
                          />
                        </div>
                        <div className="w-[80%] lg:w-[50%]">
                          <ListImage
                            image={four}
                            text="Supports gateway bridge with TLS encryption."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            // data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
            data-aos-once="false"
            className="mb-4 w-full max-w-[520px] h-fit lg:sticky lg:top-0 xl:top-[200px] overflow-hidden md:px-2 "
          >
            <div
              className="bg-white product-card grid place-content-center w-full 
            object-cover gray-drop-shadow-m rounded-lg overflow-hidden"
            >
              <SwitchTransition>
                <Transition
                  appear={true}
                  timeout={500}
                  in={true}
                  key={idx}
                  onExit={exitAnim}
                  onEnter={enter}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="!w-full flex h-80 sm:h-96">
                    <img
                      src={lorawan_sv}
                      className={` ${
                        idx === 0 ? '' : 'hidden '
                      }  !w-full !h-full object-cover object-center`}
                      alt="front view loranwan gatway product"
                      ref={imgRef}
                    />
                    <img
                      src={lorawan_fv}
                      className={` ${
                        idx === 1 ? '' : 'hidden '
                      }  h-full w-full object-center object-contain p-2 md:p-3 `}
                      alt="left view loranwan gatway product"
                      ref={imgRef}
                    />

                    <img
                      src={lorawan_lv}
                      className={` ${
                        idx === 2 ? '' : 'hidden '
                      }  h-full w-full object-center object-contain p-2 md:p-3 `}
                      alt="right view loranwan gatway product"
                      ref={imgRef}
                    />
                    <img
                      src={lorawan_rv}
                      className={` ${
                        idx === 3 ? '' : 'hidden '
                      }  h-full w-full object-cover  `}
                      alt="right view loranwan gatway product"
                      ref={imgRef}
                    />
                  </div>
                </Transition>
              </SwitchTransition>
            </div>

            <div className="mt-8 h-20 flex flex-row gap-4">
              <button
                onClick={() => setIdx(0)}
                className={` ${
                  idx === 0
                    ? 'border-[3px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 bg-gray rounded-lg active:outline-none focus:outline-none 
                bg-opacity-20 
                overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_sv_small}
                  alt="lorawan"
                  className="!h-full !w-full object-cover"
                />
              </button>

              <button
                onClick={() => setIdx(1)}
                className={` ${
                  idx === 1
                    ? 'border-[3px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 focus:outline-none rounded-lg active:outline-none flex 
                items-center justify-center bg-gray bg-opacity-20 p-1 md:p-3 overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_fv_small}
                  alt="lorawan"
                  className="h-full w-full object-cover"
                />
              </button>

              <button
                onClick={() => setIdx(2)}
                className={` ${
                  idx === 2
                    ? 'border-[3px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 focus:outline-none rounded-lg active:outline-none flex 
                items-center justify-center bg-gray bg-opacity-20 p-1 md:p-3 overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_lv_small}
                  alt="lorawan"
                  className="h-full w-full object-cover"
                />
              </button>
              <button
                onClick={() => setIdx(3)}
                className={` ${
                  idx === 3
                    ? 'border-[3px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:w-24 xl:w-28 md:h-20 focus:outline-none rounded-lg active:outline-none flex 
                items-center justify-center bg-gray bg-opacity-20 p-1 md:p-3 overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={lorawan_rv_small}
                  alt="lorawan"
                  className="h-full w-full object-cover"
                />
              </button>
            </div>
            <div className="max-w-screen-sm lg:grid hidden  mt-12">
              <MasterNav
                to={gatewayBrochure}
                title="Download the Brochure for more details"
                withIcon={true}
                showRightChevron={false}
                className="border-1  border-blue py-2 px-5 mr-auto "
              >
                <span className="sr-only">
                  Click here to download the Brochure
                </span>
                Download The Brochure
              </MasterNav>
            </div>
          </div>
        </div>

        {/* statistics table */}

        <div
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="500"
          data-aos-once="false"
          className="blade-bottom-padding hidden blade-top-padding-sm"
        >
          <h5 className="text-dark font-bold text-center px-20 pb-2 ">
            What gives LoRaWAN Gateway <br /> an edge?
          </h5>

          <div className=" max-w-screen-sm lg:w-full  mx-auto">
            <StatisticsTable viewData={gatewayData} />
          </div>
        </div>
        <div className="max-w-screen-sm mx-auto grid lg:hidden place-content-center mt-10">
          <MasterNav
            to={gatewayBrochure}
            title="Download the Brochure for more details"
            withIcon={true}
            showRightChevron={false}
            className="border-1  border-blue py-2 px-5 mr-auto "
          >
            <span className="sr-only">Click here to download the Brochure</span>
            Download The Brochure
          </MasterNav>
        </div>
      </div>
    </div>
  )
}
