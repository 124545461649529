import blogA from 'assets/blogs/smart_street_lighting_a_key_to_reducing_the_carbon_footprint.pdf'
import blogB from 'assets/blogs/smart_street_lighting_pole_a_platform_for_smart_city_applications.pdf'
import blogC from 'assets/blogs/why_smart_street_light_pole_can_be_a_game_changer_in_smart_city_development.pdf'
import blogD from 'assets/blogs/will_smart_lighting_bring_back_galileo_s_night_sky.pdf'
import bg1 from 'assets/home/blog_cards/image1.webp'
import bg2 from 'assets/home/blog_cards/image2.webp'
import bg3 from 'assets/home/blog_cards/image3.webp'
import bg4 from 'assets/home/blog_cards/image4.webp'

const blogs = [
  {
    title: 'Smart Street Lighting: A Key to Reducing the Carbon Footprint',
    desc: 'Our current energy production system from fossil fuels is one of the root causes of climate change-related catastrophe. ',
    target: blogA,
    bg: bg4,
    id: '1',
  },
  {
    title: 'Smart Street Lighting Pole: A Platform for Smart City Applications',
    desc: 'The world is rapidly moving towards massive urbanisation, which will create a huge socio-economic and environmental challenge for urban planners to',
    target: blogB,
    bg: bg2,
    id: '2',
  },
  {
    title:
      'Why smart street light pole can be a game-changer in smart city development',
    desc: 'Urban authorities across the world are vying to transform their existing cities into smart cities by laying down myriad smart applications',
    target: blogC,
    bg: bg3,
    id: '3',
  },
  {
    title: "Will smart lighting bring back Galileo's Night Sky?",
    desc: 'Urban authorities across the world are vying to transform their existing cities into smart cities by laying down myriad smart applications',
    target: blogD,
    bg: bg1,
    id: '4',
  },
]

export default blogs
