import React, { Fragment } from 'react'
import one from 'assets/home/partners/two.webp'
import two from 'assets/home/partners/one-4.png'
import three from 'assets/home/partners/three.webp'
import { Image } from 'atoms/image'
import clientsIcon from 'assets/icons/clientIcon.svg'

export default function Partners() {
  // const [isVideoON, setVideoON] = useState(false)
  // const videoRef = useRef<HTMLVideoElement>(null)
  // const videoRefMobile = useRef<HTMLVideoElement>(null)
  // const toggleVideoPreview = () => {
  //   if (isVideoON) {
  //     if (window.innerWidth < 768) {
  //       videoRefMobile.current?.pause()
  //     } else {
  //       videoRef.current?.pause()
  //     }
  //     setVideoON(false)
  //   } else {
  //     if (window.innerWidth < 768) {
  //       videoRefMobile.current?.play()
  //     } else {
  //       videoRef.current?.play()
  //     }
  //     setVideoON(true)
  //   }
  // }

  return (
    <Fragment key="home_products">
      {/* <div className="mt-4 md:-mt-16 lg:-mt-16 -xl:mt-20">
        <div className="max-w-screen-2xl mx-auto md:w-10/12 xl:w-10/12 px-3 h-80 md:h-[28rem]  xl:h-[32rem]  2xl:h-[40rem]  ">
          <div className="relative h-full w-full">
            {!isVideoON && (
              <div
                role="button"
                onClick={toggleVideoPreview}
                className="absolute top-0 cursor-pointer left-0 overflow-hidden right-0 bottom-0 grid place-content-center h-full w-full z-10 "
              >
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 57 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="28.5"
                    cy="28"
                    r="28"
                    fill="url(#paint0_linear_1529_3161)"
                  />
                  <path
                    d="M24.529 18.8174C23.1959 18.0176 21.5 18.9778 21.5 20.5324V35.8749C21.5 37.4295 23.1959 38.3897 24.529 37.5899L37.3144 29.9186C38.6091 29.1418 38.6091 27.2655 37.3144 26.4887L24.529 18.8174Z"
                    fill="#2A63E0"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1529_3161"
                      x1="28.5"
                      y1="0"
                      x2="28.5"
                      y2="56"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="white" />
                      <stop offset="0.9999" stopColor="#CCD7FF" />
                      <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            )}

            <div className="md:block hidden h-full w-full object-cover object-center rounded-xl overflow-hidden">
              <video
                poster={videoThumb}
                ref={videoRef}
                muted={false}
                onClick={toggleVideoPreview}
                controls={isVideoON}
                autoPlay={false}
                className=" w-full h-full object-center object-cover overflow-hidden cursor-pointer"
              >
                <source src={introVideo} type="video/mp4" />
              </video>
            </div>
            <div className="  md:hidden block h-full w-full object-cover object-center rounded-xl overflow-hidden">
              <video
                poster={videoThumb}
                ref={videoRefMobile}
                muted={false}
                controls
                autoPlay={false}
                className=" w-full h-full object-center   object-cover overflow-hidden cursor-pointer"
              >
                <source src={introVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div> */}
      <div className="px-3 text-center pt-12 lg:pt-20 pb-6">
        <div
          data-aos="fade-up"
          data-aos-duration="300"
          data-aos-once="false"
          className="flex flex-nowrap justify-center items-center gap-3"
        >
          <Image src={clientsIcon} alt="blogs" title="Blogs" />
          <h5 className="font-light">Our Clients</h5>
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-once="false"
        className="w-container h-fit pt-5 flex md:flex-row flex-col items-center justify-center blade-bottom-padding-sm
        max-w-screen-xl md:px-6 md:gap-x-6 lg:gap-x-0 gap-y-10 lg:gap-y-0"
      >
        <div className="w-6/12 flex place-content-center mx-auto">
          <Image
            src={one}
            alt="product one"
            className="h-full object-contain object-center mx-auto"
          />
        </div>
        <div className="flex place-content-center w-6/12 mx-auto">
          <Image
            src={two}
            alt="product one"
            className="h-full object-contain object-center mx-auto"
          />
        </div>
        <div className="flex place-content-center w-6/12 mx-auto">
          <Image
            src={three}
            alt="product one"
            className="h-full object-contain object-center mx-auto"
          />
        </div>
      </div>
    </Fragment>
  )
}
