import { MasterNav } from 'atoms/links'
import React, { useEffect } from 'react'
export default function Error404() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <div className="h-screen bg-no-repeat bg-contain bg-right-bottom bg-blue-gradient text-center gap-1  xl:gap-4">
      <div className=" px-4  grid place-content-center place-items-center  banner pt-20">
        <h1 className="text-gold-gradient text-transparent bg-clip-text  text-6xl lg:text-8xl font-bold  ">
          404
        </h1>
        <h5 className="text-white font-sequel-regular  font-normal   ">
          Page you are looking for has either <br className="md:block hidden" />
          moved permanently or does not exist.
        </h5>
        <MasterNav
          to="/"
          withIcon={false}
          showRightChevron={false}
          className=" px-6 py-3 mt-10 hover:bg-blue  hover:text-white transition-colors duration-300 ease-in-out  "
          target="_self"
        >
          Return to Home
        </MasterNav>
      </div>
    </div>
  )
}
