import ScrollDown from 'atoms/scrollDown'
import './index.css'

export default function Banner() {
  return (
    <div
      className="banner-bg bg-black banner
      flex flex-col justify-center lg:justify-start xl:justify-center  
     gap-10 md:gap-20 lg:gap-28 xl:gap-32 2xl:gap-44 items-start xl:items-center"
    >
      <div className="w-container pt-0 lg:pt-44 2xl:pt-60">
        <h1
          data-aos="fade"
          data-aos-delay="200"
          data-aos-duration="500"
          data-aos-once="false"
          className="text-gold-gradient text-[24px] md:text-3xl lg:text-4xl 2xl:text-6xl text-transparent bg-clip-text md:text-left text-center font-light"
        >
          Reimagine managing the <br className="hidden sm:block" /> streetlight
          ecosystem with <br /> Lighting Management{' '}
          <br className="hidden md:block" /> Software
        </h1>
      </div>
      <div className="w-container pb-32  lg:flex hidden ">
        <ScrollDown />
      </div>
    </div>
  )
}
