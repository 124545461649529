import { Outlet } from 'react-router-dom'
import {
  Home,
  Error404,
  Contact,
  SoftwareServices,
  Products,
  PrivacyPolicy,
  ComingSoon,
} from '../screens'
import { Header, Footer } from 'organisms'
import ScreenWrapper from 'layouts/screen'

const ViewWithHeaderAndFooter = () => {
  return (
    <ScreenWrapper>
      <Header />
      <Outlet />
      <Footer />
    </ScreenWrapper>
  )
}

const routes = () => {
  return [
    {
      path: '/',
      element: <ViewWithHeaderAndFooter />,
      children: [
        { path: '/', element: <Home /> },
        {
          path: '/software-services',
          element: <SoftwareServices />,
        },
        {
          path: '/hardware-offerings',
          element: <Products />,
        },
        {
          path: '/contact',
          element: <Contact />,
        },
        {
          path: '/privacy-policy',
          element: <PrivacyPolicy />,
        },
        {
          path: '/coming-soon',
          element: <ComingSoon />,
        },
      ],
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ]
}

export default routes
