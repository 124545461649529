import { Image } from 'atoms/image'
import React, { Fragment, MouseEventHandler } from 'react'
import logo from 'assets/globals/logo.png'
import logo_light from 'assets/globals/logo_light.png'
import { NavOption } from 'atoms/links'
import bg from 'assets/backgrounds/controller_bg_old.webp'
export default function MobileNavModal({
  toggle,
}: {
  toggle: MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <Fragment key="mobile_nav_modal">
      <div className="flex flex-col flex-nowrap bg-black h-full ">
        <div className="flex justify-between gap-10 items-center  pl-3 pr-5  py-3 w-screen  border-b-1 border-gray ">
          <Image src={logo_light} alt="Viaights Logo" className="h-8 w-auto" />
          <button
            onClick={toggle}
            className="h-10 w-10 grid place-content-center rounded-full fill-dark hover:fill-blue "
            aria-label="toggle"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              height="32"
              width="32"
            >
              <path d="M28.1 26.8c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3s-.5-.1-.7-.3l-6.8-6.8-6.8 6.8c-.2.2-.5.3-.7.3s-.5-.1-.7-.3c-.4-.4-.4-1 0-1.4l6.8-6.8-6.8-6.8c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l6.8 6.8 6.8-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L21.3 20l6.8 6.8z" />
              <path d="M19.9 40c-11 0-20-9-20-20s9-20 20-20c4.5 0 8.7 1.5 12.3 4.2.4.3.5 1 .2 1.4-.3.4-1 .5-1.4.2C27.8 3.3 24 2 20 2 10.1 2 2 10.1 2 20s8.1 18 18 18 18-8.1 18-18c0-3.2-.9-6.4-2.5-9.2-.3-.5-.1-1.1.3-1.4.5-.3 1.1-.1 1.4.3C39 12.8 40 16.3 40 19.9 39.9 31 30.9 40 19.9 40z" />
            </svg>
          </button>
        </div>
        <div
          role="Home"
          className="px-5 py-6 grid divide-y-1 divide-lightGray divide-opacity-25 "
        >
          <NavOption
            callback={toggle}
            to="/"
            ariaLabel="hardware offerings"
            className="py-3"
          >
            <span className=" hover:text-blue text-lg font-light text-white opacity-70">
              Home
            </span>
          </NavOption>
          <NavOption
            callback={toggle}
            to="hardware-offerings"
            ariaLabel="hardware offerings"
            className="py-3"
          >
            <span className=" hover:text-blue text-lg font-light text-white opacity-70">
              Hardware Offerings
            </span>
          </NavOption>
          <NavOption
            callback={toggle}
            to="/software-services"
            ariaLabel="software services"
            className="py-3"
          >
            <span className=" hover:text-blue text-lg font-light text-white opacity-70">
              Software Services
            </span>
          </NavOption>
          <NavOption
            callback={toggle}
            to="/contact"
            ariaLabel="contact us page"
            className="pt-3"
          >
            <span className=" hover:text-blue text-lg font-light text-white opacity-70">
              Contact Us
            </span>
          </NavOption>
        </div>
        <div className="absolute bottom-16 right-0 w-full z-0 flex justify-end max-w-lg">
          <Image src={bg} alt="Viaights Logo" className="w-full " />
        </div>{' '}
        <div className="py-5 text-gray font-sequel-regular text-center mt-auto font-light z-10 border-gray border-t-1">
          All Rights Reserved
        </div>
      </div>
    </Fragment>
  )
}
