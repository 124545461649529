import { Image } from 'atoms/image'
import phoneIcon from 'assets/icons/phone.svg'
import emailIcon from 'assets/icons/email.svg'
import locationIcon from 'assets/icons/location.svg'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Form from './form'
import { useEffect } from 'react'
export default function Banner() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className="banner bg-black relative ">
      <div className=" bg-black bg-[url('assets/backgrounds/contact_banner_bg.webp')] bg-no-repeat bg-cover bg-center">
        <div className="flex h-full pt-6 lg:pt-40 pb-10 lg:pb-20 xl:pb-32 flex-col gap-10 w-container lg:flex-row">
          <div className="flex-1  pt-20  lg:pt-20  xl:pt-32">
            <h1
              data-aos="fade"
              data-aos-delay="50"
              data-aos-duration="500"
              data-aos-once="false"
              className="   text-gold-gradient text-transparent font-semibold   bg-clip-text"
            >
              Let’s connect!
            </h1>
            <div className=" grid  gap-5 md:gap-3 lg:gap-5 text-white mt-4  lg:mt-8  xl:mt-10">
              <div className="flex flex-nowrap items-center lg:items-start gap-5 ">
                <Image
                  src={emailIcon}
                  alt="Email Icon"
                  loading="eager"
                  className="h-6 md:h-auto md:mt-1"
                />
                <a
                  data-aos="fade"
                  data-aos-delay="100"
                  data-aos-duration="500"
                  data-aos-once="false"
                  href="mailto:info@slscorp.com"
                  className=" text-base lg:text-xl font-light hover:underline underline-offset-4 focus:outline-none focus:underline outline-none"
                >
                  info@slscorp.com
                </a>
              </div>
              <div className="flex flex-nowrap items-start gap-5 ">
                <Image
                  className="h-6 md:h-auto mt-1"
                  src={locationIcon}
                  alt="Email Icon"
                  loading="eager"
                />
                <span
                  data-aos="fade"
                  data-aos-delay="150"
                  data-aos-duration="500"
                  data-aos-once="false"
                  className="text-base lg:text-xl font-light w-10/12 underline-offset-4 
                  focus:outline-none outline-none ml-1"
                >
                  System Level Solutions, <br />
                  32, D4, Phase 1, GIDC Estate,{' '}
                  <br className="md:block hidden" /> V.U. Nagar, Gujarat- 388121
                </span>
              </div>
              <div className="flex flex-nowrap gap-5">
                <Image
                  className="h-6 md:h-auto"
                  src={phoneIcon}
                  alt="Email Icon"
                  loading="eager"
                />
                <a
                  href="tel:+91 - 2692 - 232501"
                  data-aos="fade"
                  data-aos-delay="200"
                  data-aos-duration="500"
                  data-aos-once="false"
                  className=" text-base lg:text-xl font-light hover:underline underline-offset-4 focus:outline-none focus:underline outline-none  "
                >
                  +91 - 2692 - 232501
                </a>
              </div>
            </div>
          </div>
          <div
            className="flex-1 w-full mx-auto  max-w-screen-sm bg-blue-gradient px-3 md:px-6 lg:px-10 xl:px-12 py-6 
          rounded-3xl overflow-hidden"
          >
            <Form />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
