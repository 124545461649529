import { Blog, CaseStudy } from 'models'
import { TextNav } from 'atoms/links'
export default function ResourceCard({
  title,
  desc,
  target,
  bg,
}: Blog | CaseStudy) {
  return (
    <article
      className=" px-4 md:px-6  h-full group lg:px-8 xl:px-10  bg-white pb-8  md:pb-10 flex flex-col flex-nowrap 
    self-stretch pt-10  md:pt-20 lg:pt-28 xl:pt-32  cursor-grab relative  overflow-hidden"
    >
      <div className="absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out right-0 bottom-0 h-full w-full ">
        <img
          src={bg}
          alt={title}
          className="h-full w-full object-cover object-center select-none scale-105"
        />
        <span className="block absolute inset-0 h-full w-full bg-gradient-to-t from-black to-transparent" />
      </div>
      <h4 className=" flex-1  z-10 font-medium text-dark group-hover:text-white transition-colors duration-300  mb-3 ease-in-out">
        {title}
      </h4>

      <div className="z-10  pt-6 lg:pt-12">
        <TextNav
          target="_blank"
          to={target}
          children="Read More"
          callback={() => {
            console.log('jkefne')
          }}
          title="Read More"
        />
      </div>
    </article>
  )
}
