import React, { Fragment, useLayoutEffect } from 'react'
import About from './about'
import Blogs from './blogs'
// import Blogs from './blogs'
import Ecosystem from './ecosystem'
import Partners from './partners'
import Platform from './platform'
import Products from './products'
import Banner from './banner'
// import aos from 'aos'
// import 'aos/dist/aos.css'
export default function Home() {
  useLayoutEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <Fragment>
      <div className="overflow-hidden">
        <Banner />
        <Ecosystem />
        <Products />
        <About />
        <Platform />
        <Blogs />
        <Partners />
      </div>
    </Fragment>
  )
}

/*
original order :
  <Banner />
  <Partners />
  <Ecosystem />
  <Products />
  <SoftwareServices />
  <About />
  <Platform />
  <Blogs />
*/
