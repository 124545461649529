import React, { useEffect } from 'react'

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
    // aos.init()
  }, [])
  return (
    <main>
      <div className="blade-top-padding blade-bottom-padding-sm bg-dark-product">
        <div className="w-container ">
          <div className=" py-5 lg:py-10">
            <h1 className="text-gold-gradient text-center px-3 font-semibold  capitalize bg-clip-text text-transparent">
              Privacy Policy
            </h1>
          </div>
        </div>
      </div>
      <div className="w-container blade-top-padding-sm blade-bottom-padding-sm ">
        <div className="max-w-4xl mx-auto ">
          <h6 className="font-normal  pt-3 leading-normal text-opacity-80">
            At ViaLights, we understand that privacy is an important issue for
            visitors to the ViaLights Web site{' '}
            <a
              href="https://www.vialights.com"
              rel="noreferrer"
              target="_blank"
              className="underline underline-offset-4 font-semibold hover:text-blue"
            >
              www.viaLights.com
            </a>
            . The following information is designed to help visitors understand
            what information we gather from our site, and how we handle the
            information once we gather it.
          </h6>
          <h5 className="font-semibold  text-dark  pt-8 md:pt-10 lg:pt-12">
            Information Collection and Use
          </h5>
          <h6 className="font-normal  pt-3 leading-normal text-opacity-80">
            ViaLights is the sole owner of information collected on this site.
            We will not sell, share, or rent this information to others in ways
            different from what is disclosed in this statement. ViaLights may
            collect information from users at several different points on our
            website. ViaLights may transfer the collected information to any
            relevant unit within ViaLights, regardless of its location in the
            world.
          </h6>
          <h5 className="font-semibold  text-dark  pt-8 md:pt-10 lg:pt-12">
            General Enquiries
          </h5>
          <h6 className="font-normal  pt-3 leading-normal text-opacity-80">
            If a user contacts ViaLights with a general inquiry, the user may
            provide ViaLights with personal information (for example contact
            details). ViaLights will use this personal information to respond to
            the inquiry and track further correspondence. Information is also
            used to compile statistics on response handling, etc. This is done
            in aggregate, and no individual names or other personal information
            is retained. Personal information collected is not used for any
            other purpose.
          </h6>
          <h5 className="font-semibold  text-dark  pt-8 md:pt-10 lg:pt-12">
            Browsing
          </h5>
          <h6 className="font-normal  pt-3 leading-normal text-opacity-80">
            Cookies are small text files that a website transfers to a site
            visitor's hard disk or browser for added functionality or for
            tracking website usage. We do not use cookies to gather personal
            information such as a person's name or e-mail address. In order to
            measure the effectiveness of our online presence, ViaLights may use
            cookies to identify repeat visitors to our website and to determine
            the path visitors take on our site. Any information gathered by the
            use of cookies is compiled on an aggregate, anonymous basis.
            Examples include:
          </h6>
          <h5 className="font-semibold  text-dark  pt-8 md:pt-10 lg:pt-12">
            Feedback and Public Grievances
          </h5>
          <h6 className="font-normal pt-3 leading-normal text-opacity-80">
            We request information from the user on our feedback/grievance form.
            Here a user must provide contact information (like name and address)
            as well as the details as requested in the form. The information is
            used to study the feedback/grievance provided by the
            customer/visitor and to pass it on to the concerned
            division/department for appropriate action at their end. If we have
            trouble processing the data, the contact information provided is
            used to get in touch with the user.
          </h6>
          <h6 className="font-normal pt-3 leading-normal text-opacity-80">
            We may partner with another party to provide specific services. When
            the user signs up for these services, we will share names or other
            contact information that is necessary for the third party to provide
            these services. These parties are not allowed to use personally
            identifiable information except for the purpose of providing these
            services.
          </h6>
          <h5 className="font-semibold  text-dark  pt-8 md:pt-10 lg:pt-12">
            External links
          </h5>
          <h6 className="font-normal  pt-3 leading-normal text-opacity-80">
            This website may contain links to other websites. Please be aware
            that ViaLights is not responsible for the privacy practices of such
            other sites. We encourage our users to be aware when they leave our
            site and to read the privacy statements of each and every website
            that collects personally identifiable information. This privacy
            statement applies solely to information collected by this website.
          </h6>
          <h5 className="font-semibold  text-dark  pt-8 md:pt-10 lg:pt-12">
            Changes to ViaLights' Internet Privacy Policy
          </h5>
          <h6 className="font-normal  pt-3 leading-normal text-opacity-80">
            ViaLights may, from time to time, make changes to this policy. We
            recommend that visitors to this site revisit this privacy policy on
            every occasion to learn of new privacy practices or changes to our
            policy.
          </h6>
          <h6 className="font-normal  pt-3 leading-normal text-opacity-80">
            How to contact ViaLights about this privacy policy?
          </h6>
          <h6 className="font-normal  pt-3 leading-normal text-opacity-80">
            It's important to us at ViaLights to hear what visitors have to say
            about our services and our policies. If site visitors have any
            questions, concerns, or complaints about this privacy policy, or
            want to let us know what they think about any of our off-line and
            online products and services, they can go to contact us.
          </h6>
        </div>
      </div>
    </main>
  )
}
