import React from 'react'

export default function ListImage({
  image,
  description,
  text,
}: {
  image?: string
  description?: string
  text: string
}) {
  return (
    <li className="2xl:text-lg  xl:text-sm lg:text-sm text-base  list-none">
      <div className="flex flex-col gap-5">
        {image ? (
          <img
            className="md:h-12 md:w-12 h-10 w-10 mt-1"
            src={image}
            alt={description}
          />
        ) : (
          ''
        )}
        <span className="my-auto leading-6 font-normal block w-full md:w-11/12 lg:w-10/12">
          {' '}
          {text}
        </span>
      </div>

      {description ? (
        <dd
          className={`mt-1 2xl:text-base xl:text-sm lg:text-sm md:text-base text-white 
          text-opacity-60 font-light tracking-normal
           `}
        >
          {description}
        </dd>
      ) : (
        ''
      )}
    </li>
  )
}
