import { Image } from 'atoms/image'
import logo from 'assets/globals/logo_dark.png'
import { ExternalLink, NavOption } from 'atoms/links'
import facebookIcon from 'assets/icons/facebook.svg'
import twitterIcon from 'assets/icons/twitter.svg'
import instaIcon from 'assets/icons/insta.svg'
import linkedinIcon from 'assets/icons/linkedin.svg'
export default function Footer() {
  return (
    <footer
      className="bg-white py-12 lg:py-12 xl:py-16 bg-blue-drop-shadow bg-no-repeat"
      style={{ backgroundPosition: 'center center' }}
    >
      <div className="flex w-container md:flex-row flex-col">
        <div className="md:basis-96 grow shink grid place-content-start">
          <NavOption
            to="/"
            ariaLabel="vialights website"
            className="outline-none focus:outline-none"
          >
            <Image
              src={logo}
              alt="vialights logo"
              loading="eager"
              className="h-10 md:h-16 object-contain"
            />
          </NavOption>
          <div className="pt-4 md:block hidden">
            <span className="font-normal text-black text-opacity-60 text-sm ">
              © 2023 ViaLights. <br className="sm:block hidden" /> All rights
              reserved.
            </span>
          </div>
        </div>
        <div className="md:basis-80 grow-0 pt-4 md:pt-0 shrink">
          <h5 className="font-medium">Quick Links</h5>
          <ul className="mt-2 flex flex-col gap-y-2 lg:gap-y-0 md:mt-4">
            <li className="leading-relaxed md:leading-loose">
              <a href="/#about-us">
                <span
                  className="font-sequel-light transition-colors duration-200 font-normal text-base hover:text-opacity-100 
                text-opacity-60 text-black "
                >
                  About Us
                </span>
              </a>
            </li>
            <li className=" leading-relaxed md:leading-loose">
              <NavOption to="hardware-offerings">
                <span className="font-sequel-light transition-colors duration-200 font-normal text-base hover:text-opacity-100 text-opacity-60 text-black ">
                  Hardware Offerings
                </span>
              </NavOption>
            </li>
            <li className=" leading-relaxed md:leading-loose">
              <NavOption to="software-services">
                <span className="font-sequel-light transition-colors duration-200 font-normal text-base hover:text-opacity-100 text-opacity-60 text-black ">
                  Software Services
                </span>
              </NavOption>
            </li>
            <li className=" leading-relaxed md:leading-loose">
              <NavOption to="contact">
                <span className="font-sequel-light transition-colors duration-200 font-normal text-base hover:text-opacity-100 text-opacity-60 text-black  ">
                  Contact Us
                </span>
              </NavOption>
            </li>
            <li className=" leading-relaxed md:leading-loose">
              <NavOption to="privacy-policy">
                <span className="font-sequel-light transition-colors duration-200 font-normal text-base hover:text-opacity-100 text-opacity-60 text-black  ">
                  Privacy Policy
                </span>
              </NavOption>
            </li>
          </ul>
        </div>
        <div className="md:basis-60 grow-0 pt-4 md:pt-0 shrink">
          <h5 className=" font-medium ">Mail Us</h5>
          <div className="mt-2 md:mt-4">
            <a
              href="mailto:info@slscorp.com"
              aria-label="click to send email"
              className="font-sequel-light hover:text-opacity-100 text-opacity-60 text-dark text-base md:text-lg font-normal  transition-all duration-300 "
            >
              info@slscorp.com
            </a>
          </div>

          <h5 className=" font-medium mt-6 md:mt-8">Follow Us</h5>

          <ul className="flex flex-nowrap items-start gap-6 mt-5 md:mt-6">
            <li>
              <ExternalLink
                target="_blank"
                href={process.env.REACT_APP_FACEBOOK + ''}
                desc="Click to visit the facebook profile link"
              >
                <Image
                  className="h-6 w-6 object-contain transition-all hover:scale-105 hover:opacity-100 opacity-90 duration-300"
                  src={facebookIcon}
                  alt="Facebook icon"
                />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                target="_blank"
                href={process.env.REACT_APP_INSTA + ''}
                desc="Click to visit the facebook profile link"
              >
                <Image
                  className="h-6 w-6 object-contain transition-all hover:scale-105 hover:opacity-100 opacity-90 duration-300 "
                  src={instaIcon}
                  alt="Instagram icon"
                />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                target="_blank"
                href={process.env.REACT_APP_LINKEDIN + ''}
                desc="Click to visit the facebook profile link"
              >
                <Image
                  className="h-6 w-6 object-contain   transition-all hover:scale-105 hover:opacity-100 opacity-90 duration-300 "
                  src={linkedinIcon}
                  alt="Linkedin icon"
                />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                target="_blank"
                href={process.env.REACT_APP_TWITTER + ''}
                desc="Click to visit the facebook profile link"
              >
                <Image
                  className="h-6 w-6 object-contain  transition-all hover:scale-105 hover:opacity-100 opacity-90 duration-300 "
                  src={twitterIcon}
                  alt="Twitter icon"
                />
              </ExternalLink>
            </li>
          </ul>
        </div>
        <div className="md:hidden block">
          <div className="pt-6 md:pt-10">
            <span className="font-normal text-gray text-sm">
              © 2023 ViaLights. <br /> All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}
