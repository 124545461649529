import React, { Fragment } from 'react'
import ScrollDown from 'atoms/scrollDown'
import './index.css'

export default function Banner() {
  return (
    <Fragment key="products_banner">
      <div
        className="min-h-[700px] backdrop-blur-2xl lg:h-screen flex flex-col product-banner
      md:justify-between justify-start gap-20 pt-44 md:pt-24 lg:pt-44 banner"
      >
        <div className="w-container px-4 md:pt-12 xl:pt-10 2xl:pt-20">
          <h1
            data-aos="fade"
            data-aos-delay="200"
            data-aos-duration="500"
            data-aos-once="false"
            className="text-gold-gradient text-[24px] md:text-3xl lg:text-4xl 2xl:text-6xl text-transparent bg-clip-text 
            md:text-left text-center font-light"
          >
            Enabling smarter <br className="hidden md:block xl:hidden" />
            transition <br className="xl:block hidden" /> of cities
            <br className="hidden sm:block xl:hidden" /> with innovative{' '}
            <br className="hidden sm:block xl:block" /> smart street light
            offerings
          </h1>
        </div>
        <div className="w-container px-8 lg:flex hidden xl:pb-24 md:pb-16 text-lg  text-white flex-nowrap items-center gap-4">
          <ScrollDown />
        </div>
      </div>
    </Fragment>
  )
}
