import { Image } from 'atoms/image'
import aboutUsIcon from 'assets/icons/about-us.svg'
export default function About() {
  return (
    <div
      id="about-us"
      className="lg:pb-20 lg:bg-[url('assets/backgrounds/light_bg.webp')] bg-no-repeat bg-cover bg-top"
    >
      <div className=" lg:pt-[30rem] xl:pt-[28rem] 2xl:pt-80 lg:pb-52">
        <div className="flex blade-top-padding-sm  blade-bottom-padding-sm flex-col flex-nowrap  gap-2 md:items-center justify-start md:justify-center md:text-center w-container ">
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
            className="flex flex-nowrap items-center gap-3"
          >
            <Image
              src={aboutUsIcon}
              alt="blogs"
              title="Blogs"
              className="h-5 md:h-auto "
            />
            <h5 className="font-normal">About us</h5>
          </div>
          <h2
            className="spaced-text mt-6 font-light"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-once="false"
          >
            <span className="text-logo-gradient font-bold  text-transparent bg-clip-text ">
              ViaLights,{' '}
            </span>
            a vertical of <br className="md:block hidden" /> System Level
            Solutions,
          </h2>
          <h6
            className=" mt-2 font-light md:mt-5"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
            data-aos-once="false"
          >
            hosts a suite of smart street lighting solutions that allow real-
            <br className="md:block  hidden" /> time monitoring and control of
            luminaries, assisting in smarter
            <br className="md:block hidden" /> management of a city’s street
            light infrastructure.
          </h6>
        </div>
      </div>
    </div>
  )
}
