import { Fragment } from 'react'
import { Image } from 'atoms/image'
import productsIcon from 'assets/icons/products.svg'
export default function Title() {
  return (
    <Fragment key="title_products">
      <div
        className="bg-[url('assets/backgrounds/street_lights.webp')] bg-no-repeat bg-cover bg-top bg-black 
      blade-top-padding-sm md:blade-top-padding blade-bottom-padding-sm md:blade-bottom-padding backdrop-blur-3xl"
      >
        <div
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-once="false"
          className="w-container"
        >
          <div className="flex flex-nowrap items-center gap-3 pb-5  ">
            <Image src={productsIcon} alt="blogs" title="Blogs" />
            <h5 className="text-white font-normal">Products</h5>
          </div>
          <h2 className="text-white text-opacity-70 font-light">
            <span className="text-gold-gradient bg-clip-text text-transparent font-semibold">
              Cutting-edge hardware
            </span>{' '}
            offerings helping <br className="md:block hidden" />
            DISCOMS optimize resources and achieve{' '}
            <br className="md:block hidden" />
            smart development goals
          </h2>
        </div>
      </div>
    </Fragment>
  )
}
