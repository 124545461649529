import React, { useRef, useState } from 'react'
import ccms_fv from 'assets/products/ccms-front-view.webp'
import ccms_lv from 'assets/products/ccms-left-view.webp'
import ccms_rv from 'assets/products/ccms-right-view.webp'
import ccms_sv from 'assets/products/ccms-street-view.webp'
import ccms_sv_small from 'assets/products/ccms-street-view-small.webp'
import ccms_fv_small from 'assets/products/ccms-front-view_small.png'
import ccms_lv_small from 'assets/products/ccms-left-view_small.png'
import ccms_rv_small from 'assets/products/ccms-right-view_small.png'
import ccms from 'assets/pdf/CCMS_Brochure.pdf'
import '../home/products/style.css'
import one from 'assets/specs-icons/02/Website icons SVG-07.svg'
import two from 'assets/specs-icons/02/Website icons SVG-08.svg'
import three from 'assets/specs-icons/02/Website icons SVG-09.svg'
import four from 'assets/specs-icons/02/Website icons SVG-10.svg'
import five from 'assets/specs-icons/02/Website icons SVG-11.svg'
import six from 'assets/specs-icons/02/Website icons SVG-12.svg'
import seven from 'assets/specs-icons/02/Website icons SVG-13.svg'
import Eight from 'assets/specs-icons/02/Website icons SVG-14.svg'

import { Image } from 'atoms/image'
import { MasterNav } from 'atoms/links'
import globe from 'assets/icons/globe.svg'
import dashed_line from 'assets/icons/dashed-line.svg'
import StatisticsTable from 'organisms/statisticsTable'
import ccmsData from 'static/ccmsTable.json'
import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'
import ListImage from 'atoms/listImage'
export default function Gateway() {
  const [idx, setIdx] = useState(0)
  const imgRef = useRef(null)

  const exitAnim = (node: any) => {
    gsap.fromTo(
      node,
      { scale: 1, opacity: 1 },
      {
        opacity: 0,
        scale: 0.95,
        duration: 0.5,
      },
    )
  }

  const enter = (node: any) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        scale: 0.95,
      },
      {
        duration: 0.5,
        scale: 1,
        opacity: 1,
      },
    )
  }

  return (
    <div id="ccms">
      <div
        className="relative blade-top-padding-sm lg:blade-top-padding 
      blade-bottom-padding-sm lg:blade-bottom-padding"
      >
        <div
          className="lg:flex-row flex-col-reverse gap-x-10 
        relative flex w-container"
        >
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
            data-aos-once="false"
            className="basis-96 md:pt-6 lg:pt-0 grow shrink flex flex-col md:flex-row flex-nowrap"
          >
            <div
              className="basis-10 md:flex hidden pt-1 2xl:pt-3 grow-0 shrink flex-col 
            items-center"
            >
              <Image
                src={globe}
                alt="Globe Icon"
                className="select-none h-8 md:h-auto mb-3 md:mb-0  mr-auto"
              />
              <Image
                src={dashed_line}
                alt="Dashed Line"
                className="mx-auto select-none md:block hidden"
              />
            </div>
            <div
              className="flex-1 md:pl-3 lg:pl-7 shrink 2xl:max-w-2xl max-w-xl 
            flex flex-col gap-4 flex-nowrap"
            >
              <h3 className="md:block hidden text-blue-gradient  text-transparent bg-clip-text font-semibold">
                Centralized Control & Monitoring System
              </h3>
              <span
                className="text-black text-opacity-60 font-normal leading-normal 
              text-base  md:text-lg max-w-md xl:max-w-xl"
              >
                Centralized Control & Monitoring System is a vital product in
                the street light infrastructure that is used for group control
                of street lights, monitoring energy consumption, and detecting
                faults. The CCMS is compatible with different luminary types
                like LED, HID, CFL, FT and Induction.
              </span>
              <div className="hidden md:flex gap-x-8 xl:gap-x-12 flex-row items-start">
                <h5 className="2xl:text-xl w-[50%] text-lg tracking-tight font-medium">
                  Redefining its category space
                </h5>
              </div>
              <div className="hidden mt-4 md:flex flex-row items-start gap-x-12">
                <div className="w-[50%]">
                  <ListImage
                    image={one}
                    text="Remote ON/OFF of single/group of lights."
                  />
                </div>
                <div className="w-[50%]">
                  <ListImage image={four} text="Reduces operational costs." />
                </div>
              </div>
              <div className="hidden mt-4 md:flex flex-row items-start gap-x-12">
                <div className="w-[50%]">
                  <ListImage
                    image={two}
                    text="User configurable or astronomical schedule based ON/OFF of lights."
                  />
                </div>
                <div className="w-[50%]">
                  <ListImage
                    image={five}
                    text="Drastically reduces turnaround time for repairs and maintenance."
                  />
                </div>
              </div>
              <div className="hidden mt-4 md:flex flex-row items-start gap-x-12">
                <div className="w-[50%]">
                  <ListImage
                    image={three}
                    text="Detects faults, power thefts, and executes user-defined action."
                  />
                </div>
                <div className="w-[50%]">
                  <ListImage
                    image={six}
                    text="Secure and tamper-proof technology."
                  />
                </div>
              </div>
              <div className="hidden mt-4 md:flex flex-row items-start gap-x-12">
                <div className="w-[50%]">
                  <ListImage
                    image={seven}
                    text="Supports Firmware update over the air."
                  />
                </div>
                <div className="w-[50%]">
                  <ListImage
                    image={Eight}
                    text="Customization as per clients' requirements."
                  />
                </div>
              </div>

              {/* Mobile View  */}
              <div
                className="mt-5 md:hidden flex flex-col items-start gap-y-7
              md:gap-x-4 lg:gap-x-5 xl:gap-x-10"
              >
                <div className="basis-44 h-auto flex flex-col">
                  <h5 className="2xl:text-xl text-lg tracking-tight font-medium">
                    Redefining its category space
                  </h5>
                  <ul
                    className="font-normal pt-6 text-dark  flex flex-col flex-nowrap gap-y-6  md:gap-2 lg:gap-4  
                  list-disc"
                  >
                    <div className="w-[80%]">
                      <ListImage
                        image={one}
                        text="Enables remote ON/OFF of a single/group of feeders."
                      />
                    </div>
                    <div className="w-[80%]">
                      <ListImage
                        image={two}
                        text=" Enables automatic ON/OFF of lights based on
                        user-configurable real-time/astronomical schedule."
                      />
                    </div>
                    <div className="w-[80%]">
                      <ListImage
                        image={three}
                        text="Detects faults, power thefts, and executes user-defined action at the feeder level."
                      />
                    </div>
                    <div className="w-[70%]">
                      <ListImage
                        image={six}
                        text="Supports Firmware update over the air."
                      />
                    </div>
                  </ul>
                </div>
                <div className="2xl:basis-44 h-full justify-between flex flex-col basis-32">
                  <ul
                    className="font-normal h-full text-dark flex flex-col gap-y-6 flex-nowrap justify-between 
                  list-disc"
                  >
                    <div className="w-[80%]">
                      <ListImage
                        image={four}
                        text="Reduces operational costs."
                      />
                    </div>
                    <div className="w-[80%]">
                      <ListImage
                        image={five}
                        text="Drastically reduces turnaround time for repairs and
                      maintenance."
                      />
                    </div>
                    <div className="w-[80%]">
                      <ListImage
                        image={six}
                        text=" Secure and tamper-proof technology."
                      />
                    </div>
                    <div className="w-[80%]">
                      <ListImage
                        image={Eight}
                        text="Customization as per client's requirements."
                      />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
            data-aos-once="false"
            className="mb-4 w-full h-fit lg:sticky lg:top-0 2xl:top-[200px] md:px-2 max-w-[500px] overflow-hidden grow shrink"
          >
            <div className="bg-white overflow-hidden w-full gray-drop-shadow-m rounded-lg">
              <SwitchTransition>
                <Transition
                  appear={true}
                  timeout={500}
                  in={true}
                  key={idx}
                  onExit={exitAnim}
                  onEnter={enter}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="!w-full flex justify-center items-center h-80 sm:h-96 product-card rounded-lg overflow-hidden">
                    <img
                      src={ccms_sv}
                      className={` ${
                        idx === 0 ? '' : 'hidden'
                      } w-full !h-full object-cover object-left`}
                      alt="front view CCMS product"
                      ref={imgRef}
                    />

                    <img
                      src={ccms_fv}
                      className={` ${
                        idx === 1 ? '' : 'hidden '
                      }  h-80 w-full object-center object-contain p-2 md:p-4 `}
                      alt="left view CCMS product"
                      ref={imgRef}
                    />

                    <img
                      src={ccms_lv}
                      className={` ${
                        idx === 2 ? '' : 'hidden '
                      } pr-4  h-80 w-full object-center object-contain p-2 md:p-4 `}
                      alt="right view CCMS product"
                      ref={imgRef}
                    />
                    <img
                      src={ccms_rv}
                      className={` ${
                        idx === 3 ? '' : 'hidden '
                      } h-80 w-full object-center object-contain p-2 md:p-4 `}
                      alt="right view CCMS product"
                      ref={imgRef}
                    />
                  </div>
                </Transition>
              </SwitchTransition>
            </div>

            <div className="mt-8 h-20 flex flex-row gap-2 md:gap-4">
              <button
                onClick={() => setIdx(0)}
                className={` ${
                  idx === 0
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 active:outline-none focus:outline-none sm:w-20 md:h-20 md:w-28 bg-gray bg-opacity-20 flex items-center justify-center 
                overflow-hidden rounded-lg`}
                aria-label="thumbnail"
              >
                <Image
                  src={ccms_sv_small}
                  alt="ccms"
                  className="object-cover rounded-lg !h-full !w-full"
                />
              </button>
              <button
                onClick={() => setIdx(1)}
                className={` ${
                  idx === 1
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-28 focus:outline-none active:outline-none flex items-center 
                justify-center bg-gray bg-opacity-20 rounded-lg overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={ccms_fv_small}
                  alt="ccms"
                  className="!h-full !w-full"
                />
              </button>

              <button
                onClick={() => setIdx(2)}
                className={` ${
                  idx === 2
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-28 focus:outline-none active:outline-none flex items-center 
                justify-center bg-gray bg-opacity-20 rounded-lg
                overflow-hidden`}
                aria-label="thumbnail"
              >
                <Image
                  src={ccms_lv_small}
                  alt="ccms"
                  className="!h-full !w-full"
                />
              </button>
              <button
                onClick={() => setIdx(3)}
                className={` ${
                  idx === 3
                    ? 'border-[2px] border-solid border-gray border-opacity-60'
                    : ''
                } h-14 w-16 sm:h-16 sm:w-20 md:h-20 md:w-28 focus:outline-none active:outline-none flex items-center 
                justify-center 
                overflow-hidden bg-gray bg-opacity-20 rounded-lg`}
                aria-label="thumbnail"
              >
                <Image
                  src={ccms_rv_small}
                  alt="ccms"
                  className="!h-full !w-full"
                />
              </button>
            </div>

            <div className="max-w-2xl hidden lg:grid mt-8">
              <MasterNav
                to={ccms}
                title="Download the Brochure for more details"
                withIcon={true}
                showRightChevron={false}
                className="border-1  border-blue md:py-2 py-2 px-5 mr-auto "
              >
                <span className="sr-only">
                  Click here to download the Brochure
                </span>
                Download The Brochure
              </MasterNav>
            </div>
          </div>
          <div className="md:hidden flex basis-10 pt-1 2xl:pt-3 grow-0 shrink flex-col justify-center pb-4">
            <Image
              src={globe}
              alt="Globe Icon"
              className="select-none h-8 md:h-auto mb-3 md:mb-0  mr-auto"
            />
            <Image
              src={dashed_line}
              alt="Dashed Line"
              className="mx-auto select-none md:block hidden"
            />
            <h3 className="md:block  text-blue-gradient text-transparent bg-clip-text font-semibold">
              Centralized Control & Monitoring System
            </h3>
          </div>
        </div>

        <div className="max-w-2xl grid lg:hidden mt-8 mx-auto place-content-center">
          <MasterNav
            to={ccms}
            title="Download the Brochure for more details"
            withIcon={true}
            showRightChevron={false}
            className="border-1  border-blue md:py-2 py-2 px-5 mr-auto "
          >
            <span className="sr-only">Click here to download the Brochure</span>
            Download The Brochure
          </MasterNav>
        </div>
      </div>

      {/* statistics table */}

      <div className="pt-10 hidden max-w-2xl mx-auto lg:w-full">
        <h5
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="500"
          data-aos-once="false"
          className="text-center px-3 font-bold"
        >
          What gives Centralized Control & <br /> Monitoring System an edge?
        </h5>
        <StatisticsTable viewData={ccmsData} />
      </div>
    </div>
  )
}
